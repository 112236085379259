<div class="searchContainer" #searchBarContainer>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-form-field floatLabel="never" class="search-bar">
      <input matInput type="text" autocomplete="off" #searchInput formControlName="search"
        placeholder="Search for anything..." (keyup)="getSuggestions(getValue($event.target))"
        (focus)="startSuggestionsSubscription()" [matAutocomplete]="auto" />
      <button type="button" mat-icon-button matSuffix *ngIf="keywords.length > 0" type="button"
        (click)="clearSearchBar()">
        <mat-icon>clear</mat-icon>
      </button>
      <button name="search" type="button" mat-icon-button matSuffix (click)="onSubmit()">
        <mat-icon>search</mat-icon>
      </button>
      <mat-autocomplete #auto="matAutocomplete" class="suggestionsWidget">
        <mat-option *ngFor="let suggestion of suggestions" [value]="suggestion" (click)="onSearchProducts(suggestion)">
          {{suggestion}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</div>
