<div class="plan-bg plan-container flex-col flex-grow">
  <div class="mt-50 flex-col space-evenly items-center">
    <div class="section-subtitle plan-heading">Discover A New Way To Sell Within Your Community</div>
    <p class="card-title-sm plan-subheading">At Okra.Shop, we believe in shopping local and want
      to help people find the best products, businesses and markets in their neighborhoods.</p>
    <p class="card-title-sm plan-subheading">Businesses and markets create an online storefront, share news
      and events, accept online orders and get paid. Strengthen the connections between your
      business and community and reach new customers with Okra today.</p>
    <div>
      <img id="productDemoImage" class="mt-20" src="https://okra-public-app-data.s3.amazonaws.com/about-devices.png" />
    </div>
    <div class="flex-col mt-20 justify-center items-center">
      <span class="card-title-sm plan-subheading mr-10">Discover local businesses, farms and markets in your
        community!</span>
      <div class="mt-10">
        <a routerLink="/products"><button mat-raised-button color="accent">Shop Now</button></a>
      </div>
    </div>
  </div>
  <div class="about-spacer" ></div>
  <div class="flex-col space-evenly items-center">
    <div class="role-description-container flex-row flex-col-sm space-between items-center">
      <div class="flex-col justify-center items-center">
        <div class="section-subtitle plan-heading">As a vendor...</div>
        <ul>
          <li>Sell your products and reach new customers 24 hours a day</li>
          <li>Share local events you'll be attending on your calendar</li>
          <li>Highlight new / unique products</li>
          <li>Easily manage your product inventory</li>
          <li>Receive email notifications when orders are placed</li>
          <li>View customer order details and history</li>
          <li><span class="fw-500">Coming Soon:</span> Select market events for customer order pickup</li>
        </ul>
        <button mat-raised-button color="accent" (click)="onSalesClick()">See How Sales Work</button>
      </div>
      <div class="flex-col justify-center hide-xs">
        <img class="marketingImage"
          src="https://okra-public-app-data.s3.amazonaws.com/about-street-market-overhead.jpg" />
      </div>
    </div>
  </div>
  <div class="about-spacer" ></div>
</div>