import { Component, OnInit } from '@angular/core';

import { FeatureToggleGuardService } from 'src/app/services/feature-toggle-guard.service';
import { Vendor } from '../../models/vendor';
import { VendorService } from '../../services/vendor.service';


@Component({
  selector: 'app-view-vendors',
  templateUrl: './view-vendors.component.html',
  styleUrls: ['./view-vendors.component.css']
})
export class ViewVendorsComponent implements OnInit {
  vendors: Vendor[];
  loading: boolean;
  constructor(
    private featureToggleSvc: FeatureToggleGuardService,
    private vendorSvc: VendorService) { }

  ngOnInit(): void {
    this.loading = true;
    this.vendorSvc.getVendors().subscribe(res => {
      this.vendors = res;
      this.loading = false;
    });
  }


  public myTrackByFunction(index: number, vendor: Vendor): string {
    return vendor.id;
  }
}
