<div class="okra-container flex-row justify-center items-center flex-grow">
  <mat-card>
    <mat-card-content>
      <form>
        <div class="flex-col justify-start items-center">
          <h2>Email Verification</h2>
          <h3 *ngIf="checkingValidation">Verifying, please wait...</h3>
          <mat-error *ngIf="validationError">
            There was a problem <br /> <br />
            {{errorMessage}}
            <br />
            <br />
            <a mat-raised-button routerLink="/login">Login</a>
          </mat-error>
          <mat-progress-bar mode="indeterminate" *ngIf="checkingValidation"></mat-progress-bar>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>