<div class="flex-col card-container hide-sm" id="vendor-card" [class.detail-view]="detailView"
  [class.card-view]="!detailView">
  <div class="flex-row justify-center items-center flex-grow">
    <div class="card-title-lg flex-grow">
      {{vendor?.name}}
    </div>
    <div *ngIf="collapsible">
      <button *ngIf="!collapsed" mat-icon-button (click)="collapsed = true">
        <mat-icon>keyboard_arrow_up</mat-icon>
      </button>
      <button *ngIf="collapsed" mat-icon-button (click)="collapsed = false">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
    </div>
  </div>
  <div class="flex-row space-between flex-1" *ngIf="!collapsed">
    <div class="logo">
      <img [src]="logoPath" onerror="this.src=`/assets/okra-no-image-200w.png`;" alt="no image" id="img-logo" />
    </div>
    <div class="flex-col space-between flex-1 ml-20">
      <div class="flex-row flex-1">
        <div class="flex-col flex-50">
          <h3 class="mt-0 mb-0" *ngIf="address">{{address?.city}}, {{address?.stateprov}}</h3>
          <h3 class="mt-0 mb-0" style="white-space:pre-wrap;" *ngIf="vendor?.description">{{vendor?.description}}</h3>
        </div>
        <div class="flex-col ml-20 flex-1" *ngIf="detailView && isVendorListing === false">
          <h3 class="mt-0 mb-0">Contact Us</h3>
          <a *ngIf="website" href="{{website}}">{{website}}</a>
          <div *ngIf="email">
            E-mail:
            <a href="mailto:{{email}}">{{email}}</a>
          </div>
          <div *ngIf="formattedPhoneNumber">{{formattedPhoneNumber}}</div>
          <app-social-media *ngIf="socialMedia" [socialmedia]="socialMedia"></app-social-media>
        </div>
      </div>
      
      <div class="flex-row justify-start items-end mt-10" *ngIf="!detailView && !isVendorListing">
        <button mat-raised-button color="white" (click)="contactUs()">Contact Us</button>
        <button mat-raised-button class="ml-10" color="accent" (click)="navigateToVendorDetails()"
          *ngIf="!detailView && this.isSubscribed">Shop
          Now</button>
      </div>
    </div>
  </div>
</div>

<div class="flex-col card-container hide-gt-sm flex-grow">
  <div class="flex-row space-between flex-1">
    <div class="flex-col space-between flex-1">
      <div class="card-title-sm">{{vendor?.name}}</div>
      <div class="flex-col space-between flex-1">
        <div class="flex-col justify-start items-start">
          <div class="card-subtitle" *ngIf="address">{{address?.city}}, {{address?.stateprov}}</div>
          <div class="card-content">{{vendor?.description}}</div>
        </div>
      </div>
    </div>
    <div class="flex-col ml-20 justify-start items-center">
      <div>
        <img [src]="logoPath" onerror="this.src=`/assets/okra-no-image-200w.png`;" alt="no image" id="img-logo" />
      </div>
      <div *ngIf="!isVendorListing">
        <button *ngIf="detailView" mat-raised-button color="white" (click)="contactUs()">Contact Us</button>
        <div class="flex-col" *ngIf="!detailView">
          <button mat-raised-button color="white" (click)="contactUs()">Contact Us</button>
          <button mat-raised-button class="mt-10" color="accent" (click)="navigateToVendorDetails()">Shop Now</button>
        </div>
      </div>
    </div>
  </div>
</div>