<div class="okra-container-dark flex-row justify-center items-center flex-grow">
  <mat-card>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div *ngIf="loading" class="flex-col  justify-start items-center">
          <h3>Sending email, please wait...</h3>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf="emailSent" class="flex-col  justify-start items-center">
          <h3>Reset email sent</h3>
          <p>We've sent an email to {{form.get('email').value}}. It will have a link to reset your password.</p>
          <a mat-raised-button type="button" color="white" routerLink="/">Close</a>
        </div>
        <div *ngIf="!loading && !emailSent">
          <h2>Reset Your Password</h2>
          <mat-error *ngIf="errMessage">
            There was a problem <br />
            {{errMessage}} <br /> <br />
            Please try again or contact Okra Support <br /><br />
          </mat-error>
          <p> Enter your email and we'll send you a link to reset your password </p>
          <mat-form-field class="full-width-input">
            <input matInput placeholder="Email" formControlName="email" required>
            <mat-error>
              Please provide a valid email
            </mat-error>
          </mat-form-field>
          <mat-card-actions>
            <a mat-raised-button type="button" color="white" routerLink="/">Cancel</a>
            <button mat-raised-button color="accent" (click)="onSubmit()">Submit</button>
          </mat-card-actions>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>