import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AuthService } from "../services/auth.service";

@Component({
  selector: 'app-auth-reset',
  templateUrl: './auth-request-password-reset.component.html',
  styleUrls: ['./auth-request-password-reset.component.scss'],
})
export class AuthRequestPasswordResetComponent implements OnInit {

  form: UntypedFormGroup;
  loading: boolean;
  emailSent: boolean = false;
  errMessage: string = '';

  constructor(private fb: UntypedFormBuilder,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.loading = false;
    this.errMessage = '';
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    this.loading = true;
    const body = {
      email: this.form.get('email').value.toString().toLowerCase(),
    };
    this.authService.requestPasswordReset(body).subscribe(() => {
      this.emailSent = true;
    },
      () => {
        this.errMessage = `We were unable to send a password reset email to: ${body.email}`;
      }
    ).add(() => {
      this.loading = false;
    });
  }

}
