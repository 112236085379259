import { Address } from './address';
import { Phone } from './phone';
import { IDictionary } from './idictionary';
import { ConvertToTaxRate, ConvertToTaxRateNumeric, TaxRate, TaxRateNumeric } from './tax';
import { SubscriptionLink } from './subscription';
import { SocialMedia } from './socialMedia';

export interface Vendor {
    id: string;
    name: string;
    description: string;
    address: Address;
    phone: Phone;
    email: string;
    website: string;
    imagepath: string;
    pricetiers?: string[];
    taxes?: IDictionary<TaxRate>;
    externalids?: string[];
    subscription?: SubscriptionLink;
    socialmedia?: SocialMedia;
    updatedat?: number;
}

export interface VendorNumeric {
    id: string;
    name: string;
    description: string;
    address: Address;
    phone: Phone;
    email: string;
    website: string;
    imagepath: string;
    pricetiers?: string[];
    taxes?: IDictionary<TaxRateNumeric>;
    externalids?: string[];
    subscription?: SubscriptionLink;
}

export interface VendorLink {
    id: string;
    name: string;
}

// VendorListing represents a vendor that participates in a market but is 
// not subscribed as a vendor on Okra
export interface VendorListing {
    name: string;
    description?: string;
}

export interface VendorSalesReport {
    date: string;
    grosssales: string;
    ordercount: string;
}

export function IsVendor(v: Vendor | VendorListing): v is Vendor {
    return (v as Vendor).id !== undefined;
}

export function ConvertToVendorNumeric(v: Vendor): VendorNumeric {
    let c: VendorNumeric = {
        id: '',
        name: '',
        description: '',
        address: null,
        phone: null,
        email: '',
        website: '',
        imagepath: ''
    };
    if (!v) {
        return c;
    }
    if (v.id) {
        c.id = v.id;
    }
    if (v.name) {
        c.name = v.name;
    }
    if (v.description) {
        c.description = v.description;
    }
    if (v.imagepath) {
        c.imagepath = v.imagepath;
    }
    if (v.address) {
        c.address = v.address;
    }
    if (v.phone) {
        c.phone = v.phone;
    }
    if (v.email) {
        c.email = v.email;
    }
    if (v.website) {
        c.website = v.website;
    }
    if (v.pricetiers) {
        c.pricetiers = v.pricetiers;
    }
    if (v.taxes) {
        let numericTaxes = {} as IDictionary<TaxRateNumeric>;
        for (const key in v.taxes) {
            const numericTaxRate = ConvertToTaxRateNumeric(v.taxes[key]);
            numericTaxes[key] = numericTaxRate;
        }
        c.taxes = numericTaxes;
    }
    if (v.externalids) {
        c.externalids = v.externalids;
    }
    if (v.subscription) {
        c.subscription = v.subscription;
    }
    return c;
}

export function ConvertToProduct(v: VendorNumeric): Vendor {
    let c: Vendor = {
        id: '',
        name: '',
        description: '',
        address: null,
        phone: null,
        email: '',
        website: '',
        imagepath: ''
    };
    if (!v) {
        return c;
    }
    if (v.id) {
        c.id = v.id;
    }
    if (v.name) {
        c.name = v.name;
    }
    if (v.description) {
        c.description = v.description;
    }
    if (v.imagepath) {
        c.imagepath = v.imagepath;
    }
    if (v.address) {
        c.address = v.address;
    }
    if (v.phone) {
        c.phone = v.phone;
    }
    if (v.email) {
        c.email = v.email;
    }
    if (v.website) {
        c.website = v.website;
    }
    if (v.pricetiers) {
        c.pricetiers = v.pricetiers;
    }
    if (v.taxes) {
        let taxes = {} as IDictionary<TaxRate>;
        for (const key in v.taxes) {
            const taxRate = ConvertToTaxRate(v.taxes[key]);
            taxes[key] = taxRate;
        }
        c.taxes = taxes;
    }
    if (v.externalids) {
        c.externalids = v.externalids;
    }
    if (v.subscription) {
        c.subscription = v.subscription;
    }
    return c;

}

export function IsSubscribed(v: Vendor): boolean {

    return v?.subscription?.expiresat >= Number(Math.round(new Date().getTime() / 1000));

}