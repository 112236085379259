import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';


import { UserProfile, UserPermissions } from '../models/user';
import { Subscription } from '../models/subscription';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  usersUrl = `${environment.apiGateway}/users`;
  constructor(private http: HttpClient) { }

  deleteUser(userID: string) {
    return this.http.delete(this.getUsersUrlWithID(userID));
  }

  getUsers(): Observable<UserProfile[]> {
    return this.http.get<UserProfile[]>(this.usersUrl);
  }

  getUserPermissions(userID: string): Observable<UserPermissions> {
    return this.http.get<UserPermissions>(`${this.getUsersUrlWithID(userID)}/permissions`);
  }

  getUsersUrlWithID(id: string): string {
    return `${this.usersUrl}/${id}`;
  }

  getUserProfile(id): Observable<UserProfile> {
    return this.http.get<UserProfile>(this.getUsersUrlWithID(id));
  }

  patchUserProfile(id, body): Observable<UserProfile> {
    return this.http.patch<UserProfile>(this.getUsersUrlWithID(id), body);
  }

  patchUserPermissions(userID: string, body: any): Observable<UserPermissions> {
    return this.http.patch<UserPermissions>(`${this.getUsersUrlWithID(userID)}/permissions`, body);
  }

  getUserSubscriptions(userID: string): Observable<Subscription[]> {
    return this.http.get<Subscription[]>(`${this.getUsersUrlWithID(userID)}/subscriptions`)
  }
}
