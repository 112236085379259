export const environment = {
  production: true,
  apiGateway: 'https://api.goodfoodnl.ca',
  appDataBucket: 'https://okra-public-app-data-gjs-prod.s3.amazonaws.com/',
  userDataBucket: 'https://okra-public-user-data-gjs-prod.s3.amazonaws.com/',
  mapBoxToken: 'pk.eyJ1Ijoib2tyYXNob3AiLCJhIjoiY2t1NGY4OHBsMWtsOTJvbnRocnd6MzY4diJ9.HQY167i-K9YmzLfAB5x8OQ',
  // uses "Okra.Shop Test" Stripe business account (in test mode), not Okra.Shop Account
  stripeKey: 'pk_live_51NXpDfHaPVoCok5KTzUX133O4LkVjWhWJz4hyz2XwfIoXSrdG94Vul2qbLpBafQXPsxfaK0KM4TEF9strnJuSTCr00v4oSta0y',
  features: {
    bannerImageURL: 'https://okra-public-app-data-gjs-prod.s3.amazonaws.com/gjs-okra-banner-lg.jpg',
    bannerImageSmallURL: 'https://okra-public-app-data-gjs-prod.s3.amazonaws.com/gjs-okra-banner-sm.jpg',
    customBannerText: 'Good Food NL',
    customBannerSubtitle: '',
    customBannerSubtitle2: 'Powered by Okra',
    customCartDisclaimer: 'A weighout charge of $0.40/lb will be added for appropriate items prior to payment',
    customProductCategories: ['Baking Supplies',
      'Candy',
      'Candy Sugar Free',
      'Cereals/Grains/Seeds',
      'Chocolate/Cocoa',
      'Coconut',
      'Dried Fruit',
      'Extracts',
      'Flour',
      'Fresh Herbs',
      'Fresh Vegetables',
      'Fruit Nut Mixes',
      'Glazed Fruit',
      'Juices/Drinks',
      'Microgreens',
      'Miscellaneous',
      'Mushrooms/Caviar/Truffles',
      'Non-Food',
      'Nuts',
      'Oils',
      'Olives',
      'Pasta',
      'Prepared Sauces',
      'Pulses/Legumes',
      'Ready Made Mixes',
      'Rice',
      'Salt/Pepper',
      'Seafood',
      'Snacks',
      'Soup Bases/Gravy Mixes',
      'Spices',
      'Spreads/Fillings',
      'Sugar/Sweeteners',
      'Tinned Beans',
      'Tinned Fruit',
      'Tinned Vegetables',
      'Vinegars'],
    customTitle: 'Good Food NL',
    enableFeaturedBusiness: false,
    enableFeaturedProductsOnHomeWhenUnauthenticated: true,
    enableFooterAddress: false,
    enableOrderCustomerNotes: true,
    enablePaymentProcessing: true,
    enablePriceTiers: true,
    enableProductActiveEditing: true,
    enableProductAdd: true,
    enableProductCardVendorName: true,
    enableProductDelete: true,
    enableProductDescriptionEditing: true,
    enableProductFilters: true,
    enableProductFilterCategory: true,
    enableProductFilterTag: false,
    enableProductFiltersVendor: true,
    enableProductImageEditing: true,
    enableProductNameEditing: true,
    enableProductMultiplePrices: false,
    enableProductPricingEditing: true,
    enableProductQuantityEditing: true,
    enableProductSKUEditing: true,
    enableProfileCustomerOrdersTab: true,
    enableProfileMarketTab: false,
    enableProfileMarketVendorsTab: false,
    enableProfileOrderHistoryTab: true,
    enableProfileVendorFeaturedProducts: true,
    enableProfileVendorsQuickEditBySKU: true,
    enableProfileVendorSalesReport: true,
    enableProfileVendorTaxEditing: false,
    enableProfileVendorTaxes: true,
    enableRouteCart: true,
    enableRouteAbout: true,
    enableRouteProducts: true,
    enableRouteProductsUnauthenticated: true,
    enableRouteSearch: true,
    enableRouteSearchUnauthenticated: true,
    enableRouteSignupUnauthenticated: true,
    enableRouteVendors: true,
    enableRouteVendorSignup: true,
    enableRouteLogin: true,
  },
};
