<footer id="okra-footer">
  <div class="flex-col justify-center">
    <div class="flex-row space-around">
      <div class="flex-col flex-1 justify-start">
        <h2 class="mb-0">ABOUT</h2>
        <!-- <a routerLink="about">Okra.shop</a> -->
        <a routerLink="/legal/terms">Terms</a>
        <a routerLink="/legal/privacy">Privacy</a>
      </div>
      <div class="flex-col flex-1 justify-start">
        <h2 class="mb-0">CONTACT</h2>
        <a href="mailto:support@goodfoodnl.ca">support@goodfoodnl.ca</a>
      </div>
    </div>
    <div class="flex-col justify-center">&#169; 2022 Integrated Informatics, Inc.</div>
  </div>
</footer>