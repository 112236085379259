<div class="card">
  <div class="flex-col space-between flex-1">
    <div class="flex-row space-between" id="first-row">
      <div class="card-title-sm">{{product.name}}</div>
      <div class="flex-col justify-start items-center">
        <img [src]="product.imagepath" [alt]="product.name" onerror="this.src=`/assets/okra-no-image-200w.png`;"
          class="action-image hover-image" />
      </div>
    </div>
    <div class="flex-col justify-start flex-1 mt-10" id="second-row">
      <div *ngIf="showVendorName">{{product.vendor.name}}</div>
      <div *ngIf="product.description !== ''" class="card-content" ellipsis="... More Details" [ellipsis-content]="product.description" 
      (ellipsis-click-more)="showDetails()">{{product.description}}</div>

      <div *ngIf="prices && prices.length === 1">
        <span>
          {{prices[0]?.price | currency}} / {{prices[0]?.unit}}
        </span>
      </div>
      <div *ngIf="prices && prices.length > 1">
        <mat-form-field>
          <mat-label>Select</mat-label>
          <mat-select>
            <mat-option *ngFor="let priceSpec of prices" value="priceSpec">
              {{priceSpec?.price | currency}} / {{priceSpec?.unit}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="flex-row space-between items-end" id="second-row"
      *ngIf="productNumeric.quantity && productNumeric.quantity > 0">
      <div class="flex-col">        
        <div class="flex-row justify-start items-center">
          <div class="flex-row justify-center items-center quantity-button" (click)="subtractUnit()">-</div>
          <form>
            <mat-form-field class="quantity-form-field ml-10 mr-10">
              <input class="quantity-input" matInput type="number" [formControl]="qty" value="1"
                (change)="onChangeQty($event)">
            </mat-form-field>
          </form>
          <div class="flex-row justify-center items-center quantity-button" (click)="addUnit()">+</div>
        </div>
      </div>
      <div class="pb-1em">
        <button mat-raised-button id="buy-button" (click)="addToCart()">Add To Cart</button>
      </div>
    </div>
    <div class="flex-row justify-start items-end flex-1"
      *ngIf="!productNumeric.quantity || !(productNumeric.quantity > 0)">
      <div class="pb-1em"><i>Currently unavailable</i></div>
    </div>
  </div>
</div>