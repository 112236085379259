import { Component, Input, OnInit } from '@angular/core';
import { SocialMedia } from 'src/app/models/socialMedia';
@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.css']
})
export class SocialMediaComponent implements OnInit {

  private _socialmedia: SocialMedia;
  @Input() set socialmedia(value: SocialMedia) {
    this._socialmedia = this.formatFields(value);
  }
  get socialmedia(): SocialMedia {
    return this._socialmedia;
  }
  ig: string;
  constructor() { }

  ngOnInit(): void {
  }

  formatFields(s: SocialMedia): SocialMedia {
    if (s?.facebook && !s.facebook.includes(`facebook.com/`)) {
      s.facebook = `https://www.facebook.com/${s.facebook}`;
    }
    if (s?.instagram && !s.instagram.includes(`instagram.com/`)) {
      s.instagram = `https://www.instagram.com/${s.instagram}`;
    }
    if (s?.twitter && !s.twitter.includes(`twitter.com/`)) {
      s.twitter = `https://www.twitter.com/${s.twitter}`;
    }
    return s;
  }
}
