import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-about-sales-dialog',
  templateUrl: './about-sales-dialog.component.html',
  styleUrls: ['./about-sales-dialog.component.css']
})
export class AboutSalesDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AboutSalesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AboutSalesDialogComponent) { }

  ngOnInit(): void {
  }

}
