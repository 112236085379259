import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { AboutSalesDialogComponent } from '../about-sales-dialog/about-sales-dialog.component';
import { FeatureToggleGuardService } from '../services/feature-toggle-guard.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {

  enableVendorSignup: boolean = false;
  marketInfoForm: UntypedFormGroup;
  vendorInfoForm: UntypedFormGroup;
  constructor(
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private featureToggleSvc: FeatureToggleGuardService,
    private router: Router) { }

  ngOnInit() {
    this.getFeatureToggles();
    this.vendorInfoForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.marketInfoForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  getFeatureToggles() {
    this.enableVendorSignup = this.featureToggleSvc.enableRouteVendorSignup();
  }

  onSubscribeClick(evt: any) {
    this.router.navigate(['signup'], {
      queryParams: {
        subscription: evt
      }
    });
  }

  onSalesClick() {
    const dialogRef = this.dialog.open(AboutSalesDialogComponent);
  }

}
