import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { OrderService } from '../services/order.service';
import { UserProfile } from '../models/user';
import { UserService } from '../services/user.service';
import { SearchBarComponent } from '../search-bar/search-bar.component'
import { FeatureToggleGuardService } from '../services/feature-toggle-guard.service';

interface MenuItem {
  label: string;
  route: string;
}
@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.css']
})
export class NavHeaderComponent implements OnInit, OnDestroy {

  @ViewChild('searchBar') searchBar: SearchBarComponent;
  anonMenuItems: MenuItem[] = [
    { label: 'SHOP', route: '/products' },
    { label: 'VENDORS', route: '/vendors' },
  ];
  authMenuItems: MenuItem[] = [
    { label: 'SHOP', route: '/products' },
    { label: 'VENDORS', route: '/vendors' },
  ];

  cartItemCount = 0;
  cartSubscription: Subscription;
  currentUser: UserProfile;
  isNavCollapsed = true;
  isAuthenticated = false;
  isAdmin = false;
  navigationSubscription: Subscription;
  showCart: boolean = false;
  showAbout: boolean = false;
  showSearchBar: boolean = false;
  showSignup: boolean = false;
  showLogin: boolean = true;
  constructor(
    public orderService: OrderService,
    private authSvc: AuthService,
    public featureToggleSvc: FeatureToggleGuardService,
    private userSvc: UserService,
    private router: Router
  ) {
    this.navigationSubscription = this.router.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        this.updateCurrentUserAndFeatureToggles();
      }
    });
    this.cartSubscription = this.orderService.cartItemCount$.subscribe(count => {
      this.cartItemCount = count;
    });
  }

  ngOnInit(): void {
    this.updateCurrentUserAndFeatureToggles();
  }

  getFeatureToggles() {
    
    if (this.featureToggleSvc.enableRouteLogin() === false) {
      this.anonMenuItems = this.anonMenuItems.filter(item => !(item.route.endsWith('/vendors')));
    }
    
    const showShopRoute = (this.featureToggleSvc.enableRouteProducts() && this.isAuthenticated === true) || this.featureToggleSvc.enableRouteProductsUnauthenticated();
    if (showShopRoute === false) {
      this.authMenuItems = this.authMenuItems.filter(item => !item.route.endsWith('/products'));
      this.anonMenuItems = this.anonMenuItems.filter(item => !(item.route.endsWith('/products')));
    }
    const showVendorsRoute = this.featureToggleSvc.enableRouteVendors();
    if (showVendorsRoute === false) {
      this.authMenuItems = this.authMenuItems.filter(item => !item.route.endsWith('/vendors'));
      this.anonMenuItems = this.anonMenuItems.filter(item => !(item.route.endsWith('/vendors')));
    }
    this.showCart = this.featureToggleSvc.enableRouteCart();
    this.showAbout = this.featureToggleSvc.enableRouteAbout();
    this.showSearchBar = (this.featureToggleSvc.enableRouteSearch() && this.isAuthenticated === true) || this.featureToggleSvc.enableRouteSearchUnauthenticated();
    this.showSignup = this.featureToggleSvc.enableRouteSignupUnauthenticated();
    this.showLogin = this.featureToggleSvc.enableRouteLogin();
  }
  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }

    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
  }

  logout() {
    this.onRouteClick();
    this.isNavCollapsed = true;
    this.currentUser = null;
    this.authSvc.logout('home');
  }

  updateCurrentUserAndFeatureToggles(): void {
    this.isAuthenticated = this.authSvc.isAuthenticated();
    this.isAdmin = this.authSvc.isCurrentUserSystemAdmin();
    if (!this.currentUser && this.isAuthenticated === true) {
      this.userSvc.getUserProfile(this.authSvc.getCurrentUserId()).subscribe(res => {
        this.currentUser = res;
      });
    }
    this.getFeatureToggles();
  }

  navigateToProfile() {
    this.onRouteClick();
    this.isNavCollapsed = true;
    this.router.navigate(['profile']);
  }

  onRouteClick() {
    if (this.searchBar) {
      this.searchBar.ResetSearchBarFromURL();
    }
  }
}
