<div class="okra-container-dark flex-row justify-center items-center flex-grow">
  <mat-card>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div *ngIf="loading" class="flex-col  justify-start items-center">
          <h3>Updating password, please wait...</h3>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf="!loading">
          <h2>Reset Your Password</h2>
          <mat-form-field class="full-width-input">
            <input matInput type="password" placeholder="Password" formControlName="password" required>
            <mat-error>
              Please provide a valid password
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <input matInput type="password" placeholder="Password" formControlName="confirmPass" required>
            <mat-error>
              Please provide a valid password
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="errorMessage !== ''">
            There was a problem <br /> <br />
            {{errorMessage}}
            <br />
            <br />
          </mat-error>
          <mat-card-actions>
            <div class="flex-col justify-center">
              <a mat-raised-button type="button" color="white" routerLink="/">Cancel</a>
              <button mat-raised-button color="accent">Update Password</button>
            </div>
          </mat-card-actions>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>