import { E } from '@angular/cdk/keycodes';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CustomerLink } from '../models/order';
import { FeaturedProductLink, Product } from '../models/product';
import { Vendor, VendorSalesReport } from '../models/vendor';
import { Subscription } from '../models/subscription';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  private vendorsUrl = environment.apiGateway + '/vendors';
  constructor(
    private http: HttpClient,
  ) { }

  getVendors(subscribedOnly: boolean = true): Observable<Vendor[]> {
    let url = `${this.vendorsUrl}`
    if (subscribedOnly === false) {
      url = url + '/all';
    }
    return this.http.get<Vendor[]>(url).pipe(
      tap(res => {
        res.sort((a, b) => (a.name > b.name) ? 1 : -1)
      })
    );

  }

  getVendor(id: string): Observable<Vendor> {
    const vendorIdUrl = `${this.vendorsUrl}/${id}`;
    return this.http.get<Vendor>(vendorIdUrl);
  }

  getVendorProducts(id: string, activeOnly: boolean = true, allPriceTiers: boolean = false, skip?: number, limit?: number): Observable<Product[]> {
    let params = new HttpParams();
    let url = `${this.vendorsUrl}/${id}/products`;
    if (activeOnly === false) {
      params = params.set("activeOnly", String(activeOnly));
    }
    if (allPriceTiers === true) {
      params = params.set("pricetier", "all");
    }
    if (skip && skip > 0) {
      params = params.set("skip", skip.toString());
    }
    if (limit && limit > 0) {
      params = params.set("limit", limit.toString());
    }
    const paramString = params.toString();
    if (paramString.length > 0) {
      url = `${url}?${paramString}`;
    }

    return this.http.get<Product[]>(url).pipe(
      tap(res => {
        res.sort((a, b) => (a.name > b.name) ? 1 : -1)
      })
    );
  }

  getVendorCustomers(id: string): Observable<CustomerLink[]> {
    const url = `${this.vendorsUrl}/${id}/customers`;
    return this.http.get<CustomerLink[]>(url).pipe(
      tap(res => {
        if (!res || !res.length) {
          return;
        }
        res.sort((a: CustomerLink, b: CustomerLink) => {
          if (!a?.lastname && !b?.lastname) {
            return a?.firstname.localeCompare(b?.firstname)
          }
          return a?.lastname?.localeCompare(b?.lastname);
        })
      })
    );
  }

  getVendorSalesReport(id: string, freq: string, startdateutc: number): Observable<VendorSalesReport[]> {
    let params = new HttpParams();
    params = params.set('freq', freq);
    params = params.set('startdateutc', startdateutc);
    const paramString = params.toString();
    const url = `${this.vendorsUrl}/${id}/sales_report?${paramString}`;
    return this.http.get<VendorSalesReport[]>(url)
  }

  updateVendorCustomers(id: string, payload: any): Observable<any> {
    const url = `${this.vendorsUrl}/${id}/customers`;
    return this.http.post<any>(url, payload, { observe: "response" });
  }

  addVendor(payload: any) {
    return this.http.post<Vendor>(this.vendorsUrl, payload);
  }

  updateVendor(id: string, payload: any) {
    return this.http.patch<Vendor>(`${this.vendorsUrl}/${id}`, payload);
  }

  updateVendorStripeAccount(id: string) {
    return this.http.get<Subscription>(`${this.vendorsUrl}/${id}/stripe`);
  }

  deleteVendor(id: string) {
    return this.http.delete(`${this.vendorsUrl}/${id}`);
  }

  addFeaturedProducts(payload: FeaturedProductLink[], vendorID: string): Observable<FeaturedProductLink[]> {
    const options = {
      headers: new HttpHeaders({
        'Okra-Vendor-ID': vendorID
      })
    };
    return this.http.post<FeaturedProductLink[]>(`${this.vendorsUrl}/${vendorID}/featured_products`, payload, options)
  }

  signup(payload: any) {
    return this.http.post(`${this.vendorsUrl}/signup`, payload, { observe: "response" });
  }

  verifyVendorInfo(id: string) {
    const v: Partial<Vendor> = {
      updatedat: 0
    };
    return this.updateVendor(id, v);
  }
}
