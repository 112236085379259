import { Component, OnInit } from '@angular/core';
import { FeatureToggleGuardService } from '../services/feature-toggle-guard.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {

  showAddress: boolean = true;
  constructor(
    private featureToggleSvc: FeatureToggleGuardService
  ) { }

  ngOnInit() {
    this.getFeatureToggles();
  }

  getFeatureToggles() {
    this.showAddress = this.featureToggleSvc.enableFooterAddress();
  }
}
