import { Component, Input, OnInit } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { FeatureToggleGuardService } from 'src/app/services/feature-toggle-guard.service';
import { UrlLookupService } from 'src/app/services/url-lookup.service';
import { VendorService } from 'src/app/services/vendor.service';
import { Vendor } from '../../models/vendor';

type VendorDetailPage = 'shop' | 'info';
@Component({
  selector: 'app-vendor-detail',
  templateUrl: './vendor-detail.component.html',
  styleUrls: ['./vendor-detail.component.css']
})
export class VendorDetailComponent implements OnInit {
  @Input() id: string = '';
  loadingMessage: string = '';
  loadingV: boolean;
  page: VendorDetailPage = 'shop';
  vendor: Vendor;
  constructor(
    private featureToggleSvc: FeatureToggleGuardService,
    private gaSvc: AnalyticsService,
    private location: Location,
    private route: ActivatedRoute,
    private urlLookupSvc: UrlLookupService,
    private vendorSvc: VendorService,
    
  ) { }

  ngOnInit(): void {
    this.getVendor();
    this.getVendorUrl();
  }


  getVendor(): void {
    this.loadingV = true;
    this.loadingMessage = 'Loading vendor details, please wait...';

    let id = this.route.snapshot.paramMap.get('id');
    if (id && this.id !== id) {
      this.id = id;
    }

    this.vendorSvc.getVendor(this.id).subscribe(res => {
      this.vendor = res;
      this.gaSvc.event('view_item_list', {
        item_id: this.vendor.id,
        item_name: this.vendor.name
      });
    }).add(() => {
      this.loadingV = false;
      this.loadingMessage = '';
    });
  }

  getVendorUrl(): void {
    let id = this.route.snapshot.paramMap.get('id');
    this.urlLookupSvc.reverseLookup(null, id).subscribe(res => {
      if (!res && !res.url) {
        return;
      }
      this.location.replaceState("vendors/"+res.url);
    });
  }

  pageToggleChange(e: MatButtonToggleChange) {
    const nextPage = e.value as VendorDetailPage;
    if (nextPage != this.page) {
      this.setPage(nextPage);
    }
  }

  setPage(pageEvt?: string) {
    if (pageEvt as VendorDetailPage) {
      this.page = pageEvt as VendorDetailPage;
    }
  }
}
