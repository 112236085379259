import { Component, OnInit, Input, ViewEncapsulation, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { Router } from '@angular/router';
import { IPageInfo } from '@iharbeck/ngx-virtual-scroller';
import { Subject } from 'rxjs/internal/Subject';
import { BehaviorSubject } from 'rxjs';

import { Product } from '../models/product';
import { ProductService } from '../services/product.service';
import { SearchResults } from '../models/searchResults';
import { VendorLink, Vendor } from '../models/vendor';
import { VendorService } from '../services/vendor.service';
import { Subscription } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { FeatureToggleGuardService } from '../services/feature-toggle-guard.service';
import { tap, distinctUntilChanged } from 'rxjs/operators';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductsComponent implements OnInit, OnDestroy {
  @Input() products: Product[];
  @Input() title = 'All Products';
  @Input() vendorId?;
  @Input() vendors?: Vendor[];
  @Input() searchContext: string = 'product';

  @ViewChild('filterNav') filterNav: MatSidenav;

  clearExisting: boolean;
  chunkLoading: boolean;
  enableFilters: boolean = false;
  enableFilterCategory: boolean = false;
  enableFilterVendor: boolean = false;
  filterNavMode: string = 'side'
  filterCategories: string[] = [];
  filterCategoryCtrl: UntypedFormControl = new UntypedFormControl();
  filterVendorCtrl: UntypedFormControl = new UntypedFormControl();
  filterVendors: BehaviorSubject<VendorLink[] | Vendor[]> = new BehaviorSubject<VendorLink[] | Vendor[]>([]);
  form: UntypedFormGroup;
  keywords: string = '';
  limit: number = 50;
  loadedAll: boolean = false;
  loading: boolean;
  searchCtrl: UntypedFormControl = new UntypedFormControl();
  selectedCategories: string = '';
  skip: number = 0;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    private featureToggleSvc: FeatureToggleGuardService,
    private fb: UntypedFormBuilder,
    private productSvc: ProductService,
    private router: Router,
    private vendorSvc: VendorService,
    changeDetectorRef: ChangeDetectorRef, media: MediaMatcher
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 900px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    
   }

  ngOnInit(): void {
    this.getFeatureToggles();
    if (this.enableFilters) {

      this.form = this.fb.group({
        searchProducts: [this.keywords]
      });
    }
    if (!this.products) {
      this.loading = true;
      const parameters = new URLSearchParams(window.location.search);
      const qParams = parameters.get('q');
      const skipParam = parameters.get('skip');
      const limitParam = parameters.get('limit');

      if (qParams && qParams?.length > 0) {
        this.keywords = qParams;
      }
      if (skipParam && skipParam?.length > 0) {
        this.skip = parseInt(skipParam);
      }
      if (limitParam && limitParam?.length > 0) {
        this.limit = parseInt(limitParam);
      }

      if (!this.vendorId) {
        if (this.vendors) {
          this.filterVendors.next(this.vendors);
          this.vendorId = this.vendors.map(v => v.id).join(',');
        } else {
          this.setVendorList();
        }
      }

      this.searchProducts();
    }

    this.filterCategories = this.productSvc.getProductCategories();
  }

  ngOnDestroy() {
  }

  showContext() {
    return "Search this " + this.searchContext;
  }

  getFeatureToggles() {
    this.enableFilters = this.featureToggleSvc.enableProductFilters();
    this.enableFilterCategory = this.featureToggleSvc.enableProductFilterCategory();
    this.enableFilterVendor = this.featureToggleSvc.enableProductFilterVendor() && !this.vendorId;
  }

  toggleFilterNav() {
    this.filterNav.toggle();
  }

  clearProductSearchBar() {
    this.keywords = '';
    this.skip = 0;
    this.loading = true;
    this.chunkLoading = true;
    this.loadedAll = false;
    this.clearExisting = true;
    this.searchCtrl.setValue(this.keywords);
    this.searchProducts();
  }

  resetFilterandSearch() {

    this.keywords = '';
    this.selectedCategories = null;
    if (this.searchContext === 'product') {
      this.vendorId = null;
    } else if (this.searchContext === 'market') {
      if (this.vendors) {
        this.vendorId = this.vendors.map(v => v.id).join(',');
      }
    }
    this.skip = 0;
    this.clearExisting = true;
    this.loading = true;
    this.chunkLoading = true;
    this.loadedAll = false;

    this.filterVendorCtrl.setValue([], { emitEvent: false });
    this.filterCategoryCtrl.setValue([], { emitEvent: false });
    this.searchCtrl.setValue([]);

    this.searchProducts();
  }

  public fetchMore(event: IPageInfo) {
    if (this.loadedAll || this.products == undefined || event.endIndex !== this.products.length - 1) return;
    this.chunkLoading = true;
    this.loadedAll = false;
    if (this.products.length > 0) {
      this.skip = this.products.length;
    } else {
      this.skip = 0;
    }
    this.searchProducts();
  }

  setVendorList() {
    this.vendorSvc.getVendors().subscribe(vendors => {
      this.filterVendors.next(vendors);
    });;
  }

  searchProducts() {
    this.nonGeoSearch();
  }

  geoSearch(position) {
    // TODO: integrate position information into search
    // @ts-ignore
    // const lon = -107.8829;
    // @ts-ignore
    // const lat = 37.273;
    // let querystring = keywords + '&lon=' + lon + '&lat=' + lat + '&lon2=' + lon2 + '&lat2=' + lat2; 
    if (this.currentPosition != undefined && this.currentPosition != position) {
      this.skip = 0;
    }


    this.productSvc.searchProducts(this.keywords, true, this.skip, this.limit, this.vendorId, this.selectedCategories).subscribe(res => {
      if (res.products?.length === 0) {
        if (this.clearExisting) {
          this.products = res.products;
          this.clearExisting = false;
        }
        this.loadedAll = true;
        this.loading = false;
        this.chunkLoading = false;
        return;
      }
      if (!this.products || !this.products.length) {
        this.products = res.products;
      } else {
        const curlen = this.products.length;
        if (this.clearExisting) {
          this.products = res.products;
        } else {
          this.products = this.products.concat(res.products);
        }
        //if (this.products.length == curlen) {
        //  this.loadedAll = true;
        //}
      }

      this.loading = false;
      this.chunkLoading = false;
      this.clearExisting = false;
    });
  }

  nonGeoSearch() {
    this.productSvc.searchProducts(this.keywords, true, this.skip, this.limit, this.vendorId, this.selectedCategories).subscribe(res => {
      if (!res.products || !res.products.length || res.products.length < 1) {
        if (this.clearExisting) {
          this.products = res.products;
          this.clearExisting = false;
        }
        this.loadedAll = true;
        this.loading = false;
        this.chunkLoading = false;
        return;
      }
      if (!this.products || !this.products.length) {
        this.products = res.products;
      } else {
        const curlen = this.products.length;
        if (this.clearExisting) {
          this.products = res.products;
        } else {
          this.products = this.products.concat(res.products);
        }
        // if (this.products.length == curlen) {
        //   this.loadedAll = true;
        // }
      }
      this.loading = false;
      this.chunkLoading = false;
      this.clearExisting = false;
    });
  }

  public filterByVendor(event: any) {

    this.vendorId = event.value.map(v => v.id).join(',');

    //when no vendors selected, filter products to all vendors in market
    if (!this.vendorId) {
      if (this.vendors) {
        this.vendorId = this.vendors.map(v => v.id).join(',');
      }
    }

    this.skip = 0;
    this.clearExisting = true;
    this.loading = true;
    this.chunkLoading = true;
    this.loadedAll = false;
    this.searchProducts();
  }

  public onFilterCategoryChanged(event: any) {

    this.selectedCategories = event.value;
    this.skip = 0;
    this.clearExisting = true;
    this.loading = true;
    this.chunkLoading = true;
    this.loadedAll = false;
    this.searchProducts();

  }

  navigateToProduct(id: any) {
    this.router.navigate(['products', id]);
  }

  getVendorProducts() {
    this.vendorSvc.getVendorProducts(this.vendorId, true, false, this.skip, this.limit).subscribe(res => {
      if (!res || !res?.length) {
        this.loadedAll = true;
        this.loading = false;
        this.chunkLoading = false;
        return;
      }
      if (!this.products || !this.products?.length) {
        this.products = res;
      } else {
        // const curlen = this.products.length;
        this.products = this.products.concat(res);
        // if (this.products.length == curlen) {
        //   this.loadedAll = true;
        // }
      }
      this.loading = false;
      this.chunkLoading = false;
    });
  }


  public myTrackByFunction(index: number, product: Product): string {
    return product.id;
  }

  onSubmit() {
    //const searchControl = this.form.get('searchProducts');
    this.keywords = this.searchCtrl.value;
    if (this.keywords && this.keywords.length > 0) {
      this.onSearchProducts();
    }
  }
  onSearchProducts(suggestion?: string) {

    if (suggestion) {
      // split on whitespace, 
      // then remove non-alphanumeric characters (include underscores), 
      // then filter based on length
      this.keywords = suggestion.split(' ').map(term => term.replace(/\W/g, '')).filter(term => term.length > 2).join(' ')
    }

    this.keywords = this.keywords.trim();
    this.skip = 0;
    this.clearExisting = true;
    this.loading = true;
    this.chunkLoading = true;
    this.loadedAll = false;

    this.searchProducts();
  }

}
