import { IDictionary } from './idictionary';
import { ConvertToTaxRate, ConvertToTaxRateNumeric, TaxRate, TaxRateNumeric } from './tax';

import { VendorLink } from './vendor';
export interface Product {
    id: string;
    name: string;
    description: string;
    prices: PriceSpecification[];
    quantity: string;
    vendor: VendorLink;
    imagepath: string;
    active: boolean;
    sku?: string;
    taxes?: IDictionary<TaxRate>;
    tags?: string[];
}
export interface PriceSpecification {
    price: string;
    unit: string;
    tier?: string;
}

export interface ProductNumeric {
    id: string;
    name: string;
    description: string;
    prices: NumericPriceSpecification[];
    quantity: number;
    vendor: VendorLink;
    imagepath: string;
    active: boolean;
    sku?: string;
    taxes?: IDictionary<TaxRateNumeric>;
    tags?: string[];
}
export interface NumericPriceSpecification {
    price: number;
    unit: string;
    tier?: string;
}

export function ConvertToProductNumeric(p: Product): ProductNumeric {
    let c: ProductNumeric = {
        id: '',
        name: '',
        description: '',
        prices: [],
        quantity: 0,
        vendor: null,
        imagepath: '',
        active: true,
    };
    if (!p) {
        return c;
    }
    if (p.id) {
        c.id = p.id;
    }
    if (p.name) {
        c.name = p.name;
    }
    if (p.description) {
        c.description = p.description;
    }
    if (p.imagepath) {
        c.imagepath = p.imagepath;
    }
    if (p.sku) {
        c.sku = p.sku;
    }
    if (p.quantity) {
        c.quantity = Number(p.quantity.replace(/,/g, ''));
    }
    if (p.active === false) {
        c.active = false;
    }
    if (p.vendor) {
        c.vendor = p.vendor;
    }
    if (p.taxes) {
        const numericTaxes = {} as IDictionary<TaxRateNumeric>;
        for (const key in p.taxes) {
            const numericTaxRate = ConvertToTaxRateNumeric(p.taxes[key]);
            numericTaxes[key] = numericTaxRate;
        }
        c.taxes = numericTaxes;
    }

    if(p.tags) {
        c.tags = p.tags;
    }

    let numericPrices: NumericPriceSpecification[] = [];
    p.prices.map(spec => {
        const numericPriceSpec: NumericPriceSpecification = { price: Number(spec.price.replace(/,/g, '')), unit: spec.unit, tier: spec.tier };
        numericPrices.push(numericPriceSpec);
    });
    c.prices = numericPrices;
    return c;
}

export function ConvertToProduct(p: ProductNumeric): Product {
    let c: Product = {
        id: '',
        name: '',
        description: '',
        prices: [],
        quantity: '',
        vendor: null,
        imagepath: '',
        active: true,
    };
    if (!p) {
        return c;
    }
    if (p.id) {
        c.id = p.id;
    }
    if (p.name) {
        c.name = p.name;
    }
    if (p.description) {
        c.description = p.description;
    }
    if (p.imagepath) {
        c.imagepath = p.imagepath;
    }
    if (p.sku) {
        c.sku = p.sku;
    }
    if (p.quantity) {
        c.quantity = p.quantity.toString();
    }
    if (p.active === false) {
        c.active = false;
    }
    if (p.vendor) {
        c.vendor = p.vendor;
    }
    if (p.tags) {
        c.tags = p.tags;
    }
    if (p.taxes) {
        const taxes = {} as IDictionary<TaxRate>;
        for (const key in p.taxes) {
            const taxRate = ConvertToTaxRate(p.taxes[key]);
            taxes[key] = taxRate;
        }
        c.taxes = taxes;
    }

    let prices: PriceSpecification[] = [];
    p.prices.map(spec => {
        const priceSpec: PriceSpecification = { price: spec.price.toString(), unit: spec.unit, tier: spec.tier };
        prices.push(priceSpec);
    });
    c.prices = prices;
    return c;
}

export interface FeaturedProductLink {
    id?: string;
    productid: string;
    productname: string;
    vendor: VendorLink;
}
