<app-banner [text]="customBannerText" [subtitle]="customBannerSubtitle" [subtitle2]="customBannerSubtitle2">
</app-banner>

<div class="flex-col">
  <div>
    <div class="section-subtitle">Featured Products</div>
    <div *ngIf="showFeaturedProductsLoading" class="flex-col justify-start items-center">
      <h3>Loading...</h3>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div>
      <span *ngIf="!showFeaturedProductsLoading && products.length === 0"
      class="flex-row justify-center">There are currently no featured products</span>
      <virtual-scroller [horizontal]="true" #scroll [items]="products" *ngIf="products && products.length > 0">
        <app-product-card *ngFor="let product of scroll.viewPortItems; let i = index; trackBy: myTrackByFunction"
          [product]="product">
        </app-product-card>
        <button mat-stroked-button class="moreCard" (click)="seeMoreProducts()">
          Shop All Products
        </button>
      </virtual-scroller>
    </div>
  </div>
  <app-feature-business *ngIf="showFeaturedBusiness"></app-feature-business>
</div>