import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { ProductService } from '../services/product.service';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { SearchResults } from '../models/searchResults';
import { AnalyticsService } from '../services/analytics.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchBarComponent implements OnInit {
  @ViewChild('searchInput') private searchInputElRef: ElementRef;
  form: UntypedFormGroup;
  suggestions = new Set();
  suggestionsSubject: Observable<SearchResults>;
  suggestionsSubscription: Subscription;
  keywords = '';
  keywordsSubject$ = new Subject<string>();

  constructor(
    private gaSvc: AnalyticsService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private productSvc: ProductService) { }

  ngOnInit() {
    this.getSearchTermsFromURL();

    this.form = this.fb.group({
      search: [this.keywords]
    });

    this.suggestionsSubject = this.keywordsSubject$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(keywords => keywords.length > 2 ? this.productSvc.searchProducts(keywords) : of(null))
    );
  }

  getSuggestions(keywords: string) {
    this.keywords = keywords;
    if (keywords.length < 3) {
      this.clearSearchSuggestions();
    }
    this.keywordsSubject$.next(keywords.trim());
  }

  getValue(target: EventTarget): string {
    return (target as HTMLInputElement).value;
  }

  startSuggestionsSubscription() {
    if (this.suggestionsSubscription && !this.suggestionsSubscription.closed) {
      return;
    }
    this.suggestionsSubscription = this.suggestionsSubject.subscribe((result) => {
      const keywords = this.form.get('search').value;
      if (keywords && keywords.length > 2) {
        this.suggestions = new Set(result.products.map(item => item.name));
      } else {
        this.clearSearchSuggestions();
      }
    }
    );
  }

  getSearchTermsFromURL() {
    const parameters = new URLSearchParams(window.location.search);
    const qParams = parameters.get('q');
    if (qParams && qParams?.length > 0) {
      this.keywords = qParams;
    }
  }

  onSubmit() {
    const searchControl = this.form.get('search');
    this.keywords = searchControl.value;
    if (this.keywords && this.keywords.length > 0) {
      this.onSearchProducts();
    }
  }

  clearSearchBar() {
    this.clearSearchSuggestions();
    this.keywords = '';
    this.form.get('search').setValue(this.keywords);
  }

  clearSearchSuggestions() {
    this.suggestions = new Set();
  }

  public ResetSearchBarFromURL() {
    this.clearSearchBar();
    this.getSuggestions(this.keywords);
    this.form.get('search').setValue(this.keywords);
  }

  onSearchProducts(suggestion?: string) {
    this.unsubscribeSearchSuggestions();
    if (suggestion) {
      // split on whitespace, 
      // then remove non-alphanumeric characters (include underscores), 
      // then filter based on length
      this.keywords = suggestion.split(' ').map(term => term.replace(/\W/g, '')).filter(term => term.length > 2).join(' ')
    }
    this.keywords = this.keywords.trim();
    this.form.get('search').setValue(this.keywords);
    this.clearSearchSuggestions();
    if (this.searchInputElRef.nativeElement) {
      this.searchInputElRef.nativeElement.blur();
    }
    this.gaSvc.event('view_search_results', { 'search_term': this.keywords });
    this.router.navigate(['search'], { queryParams: { q: this.keywords } });
  }

  unsubscribeSearchSuggestions(): void {
    this.clearSearchSuggestions();
    this.suggestionsSubscription.unsubscribe();
  }

}
