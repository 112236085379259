import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

import { ContactUsComponent } from '../../../../contact-us/contact-us.component';
import { GetFormattedPhoneNumber } from 'src/app/models/phone';
import { IsSubscribed, IsVendor, Vendor, VendorListing } from 'src/app/models/vendor';
import { Address } from 'src/app/models/address';
import { SocialMedia } from 'src/app/models/socialMedia';

@Component({
  selector: 'app-vendor-card',
  templateUrl: './vendor-card.component.html',
  styleUrls: ['./vendor-card.component.css']
})
export class VendorCardComponent implements OnInit {
  @Input() collapsible: boolean = false;
  @Input() detailView: boolean = true;
  @Input() vendor: Vendor | VendorListing;

  address: Address = null;
  collapsed: boolean = false;
  email: string = '';
  formattedPhoneNumber: string = '';
  isSubscribed: boolean = false;
  isVendorListing: boolean = false;
  logoPath: string = '';
  socialMedia: SocialMedia = null;
  website: string = '';
  constructor(
    public dialog: MatDialog,
    private router: Router) {
  }

  ngOnInit(): void {
    this.address = null;
    this.email = '';
    this.formattedPhoneNumber = '';
    this.website = '';
    this.isVendorListing = !IsVendor(this.vendor);
    if (this.isVendorListing === false) {
      const v = this.vendor as Vendor;
      this.isSubscribed = IsSubscribed(v);
      if (v.address) {
        this.address = v.address;
      }
      if (v.email) {
        this.email = v.email;
      }
      this.formattedPhoneNumber = GetFormattedPhoneNumber(v?.phone);
      if (v.imagepath) {
        this.logoPath = v.imagepath;
      }
      if (v.socialmedia) {
        this.socialMedia = v.socialmedia;
      }
      if (v.website) {
        this.website = v.website;
      }
    }
    if (!this.logoPath) {
      this.logoPath = '/assets/okra-no-image-200w.png';
    }
  }

  navigateToVendorDetails() {
    if (this.isVendorListing === true) {
      return;
    }
    const v = this.vendor as Vendor;
    this.router.navigate(['vendors', v.id]);
  }

  contactUs(): void {
    if (this.isVendorListing === true) {
      return;
    }
    const v = this.vendor as Vendor;
    this.dialog.open(ContactUsComponent, {
      data: {
        website: v.website,
        phone: v.phone,
        email: v.email,
        socialmedia: this.socialMedia,
      },
    });
  }
}
