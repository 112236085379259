<div class="flex-col flex-grow">
    <h1 mat-dialog-title>Sell Your Products on Okra</h1>
    <div mat-dialog-content class="mat-typography">
        <div class="flex-col">
            <span class="card-subtitle">Okra lets customers buy your products anytime they want, helping you grow your
                business. Let's see how it works.</span>
            <div class="mt-50"></div>
            <div class="flex-col">
                <div class="flex-row flex-col-sm space-between">
                    <div class="flex-col space-evenly items-center flex-1">
                        <span class="card-subtitle">Create Your Storefront</span>
                        <span class="card-content">Customize your profile by adding your business logo, description,
                            contact information.</span>
                    </div>
                    <div class="ml-20 mr-20 flex-col justify-center items-center hide-xs">
                        <img class="image" src="https://okra-public-app-data.s3.amazonaws.com/about-open-shop.jpg"
                            alt="no image" />
                    </div>
                </div>
                <div class="mt-50 flex-row flex-col-sm space-between">
                    <div class="ml-20 mr-20 flex-col justify-center items-center hide-xs">
                        <img class="image" src="https://okra-public-app-data.s3.amazonaws.com/about-inventory.jpg"
                            alt="no image" />
                    </div>
                    <div class="flex-col space-evenly items-center flex-1">
                        <span class="card-subtitle">Manage Your Inventory</span>
                        <span class="card-content">Easily add and update the products in your online store. You can
                            upload product images, set pricing and quantity, and add tags and categories to help
                            customers find your products faster.</span>
                    </div>

                </div>
                <div class="mt-50 flex-row flex-col-sm justify-center items-center">
                    <div class="flex-col space-evenly items-center flex-1">
                        <span class="card-subtitle">Receive and Fulfill Orders</span>
                        <span class="mt-10 card-content">You'll receive email notifications when a customer places an
                            order and can easily view details about the order in the email or within Okra, as well as
                            mark the order accepted once you have it ready for pickup.</span>
                    </div>
                    <div class="ml-20 mr-20 flex-col justify-center items-center hide-xs">
                        <img class="image" src="https://okra-public-app-data.s3.amazonaws.com/about-shopping-bag.jpg"
                            alt="no image" />
                    </div>
                </div>
                <div class="mt-50 flex-row flex-col-sm justify-center items-center">
                    <div class="ml-20 mr-20 flex-col justify-center items-center hide-xs">
                        <img class="image" src="https://okra-public-app-data.s3.amazonaws.com/about-payment.jpg"
                            alt="no image" />
                    </div>
                    <div class="flex-col space-evenly items-center flex-1">
                        <span class="card-subtitle">Get Paid</span>
                        <span class="mt-10 card-content">Okra streamlines the shopping experience and reduces credit
                            card fees by letting customers order from multiple stores in a single transaction.</span>
                        <span class="mt-10 card-content">Okra partners with Stripe for secure payment
                            processing. You'll automatically have a Stripe account created when you sign up as a vendor.
                            There is no additional cost to having this account; it's included in your Okra
                            subscription.</span>
                        <span class="mt-10 card-content">Customers pay for their order online through Okra using Stripe,
                            and funds are automatically distributed to your Stripe account. Standard credit card
                            processing fees from Stripe are deducted from order totals before funds are transferred to
                            your Stripe account (2.9% + $0.30 / credit card charge); Okra doesn't charge
                            any additional service or application fees per order.</span>
                        <span class="mt-10 card-content">You can set the frequency that Stripe pays out funds
                            from your Stripe account to your connected bank account or debit card. There is a 0.25% +
                            $0.25 fee deducted automatically by Stripe each time you receive your payout. For example,
                            if you have $100 in your Stripe account, you would receive a payout of $99.50 after the
                            Stripe fee.</span>
                    </div>

                </div>

            </div>
            <span class="card-content"></span>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button color="white" [mat-dialog-close]>Close</button>
    </div>
</div>