export interface TaxRate {
    name: string;
    description?: string;
    percentage: string;
    inclusive?: boolean;
    active?: boolean;
}

export interface TaxRateNumeric {
    name: string;
    description?: string;
    percentage: number;
    inclusive?: boolean;
    active?: boolean;
}

export function ConvertToTaxRateNumeric(t: TaxRate): TaxRateNumeric {
    let c: TaxRateNumeric = {
        name: '',
        percentage: 0,
        active: true,
    };
    if (!t) {
        return c;
    }
    if (t.name) {
        c.name = t.name;
    }
    if (t.description) {
        c.description = t.description;
    }
    if (t.percentage) {
        c.percentage = Number(t.percentage);
    }
    if (t.inclusive === false || t.inclusive === true) {
        c.inclusive = t.inclusive;
    }
    if (t.active === false || t.active === true) {
        c.active = t.active;
    }

    return c;
}

export function ConvertToTaxRate(t: TaxRateNumeric): TaxRate {
    let c: TaxRate = {
        name: '',
        percentage: '0',
        active: true,
    };
    if (!t) {
        return c;
    }
    if (t.name) {
        c.name = t.name;
    }
    if (t.description) {
        c.description = t.description;
    }
    if (t.percentage) {
        c.percentage = t.percentage.toString();
    }
    if (t.inclusive === false || t.inclusive === true) {
        c.inclusive = t.inclusive;
    }
    if (t.active === false || t.active === true) {
        c.active = t.active;
    }

    return c;
}