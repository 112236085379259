<div class="flex-col">
  <h3 mat-dialog-title>Contact Us</h3>
  <div mat-dialog-content>
    <a class="link-responsive" *ngIf="website.length > 0" href="{{website}}">Click here to visit our website</a>
    <div class="contactGrid">
      <div class="emailLabel card-content">E-mail:</div>
      <div class="flex-row justify-start items-center">
        <a class="link-responsive" *ngIf="email.length > 0" target="_blank" href="mailto:{{email}}">Click here to send
          us an email</a>
      </div>
      <div class="phoneLabel card-content">Phone:</div>
      <div class="card-content">{{phone}}</div>

      <app-social-media *ngIf="socialmedia" [socialmedia]="socialmedia"></app-social-media>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button (click)="closeDialog()">Close</button>
  </div>
</div>