<div class="okra-container-dark flex-row justify-center items-center flex-grow">
  <mat-card>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div *ngIf="loading" class="flex-col justify-start items-center">
          <h3>Logging in, please wait...</h3>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf="!loading">
          <h2>Log In</h2>
          <mat-error *ngIf="loginInvalid">
            There was a problem <br />
            The username and password were not recognised
          </mat-error>
          <mat-form-field class="full-width-input">
            <input matInput placeholder="Username" formControlName="username" autocomplete="username" required>
            <mat-error>
              Please provide a valid username
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <input matInput type="password" placeholder="Password" formControlName="password"
              autocomplete="current-password" required>
            <mat-error>
              Please provide a valid password
            </mat-error>
          </mat-form-field>
          <mat-card-actions>
            <div class="flex-col justify-center">
              <button id="submitlogin" mat-raised-button color="accent" (click)="onSubmit()">Log In</button>
            </div>
          </mat-card-actions>
        </div>
      </form>
      <div *ngIf="!loading">
        <p><a [routerLink]="'/reset_password'">Can't remember your password?</a></p>
        <br>
        <br>
        <div *ngIf="showSignup">
          <p>Don't have an Okra account?</p>
          <p><a [routerLink]="'/signup'">Sign Up</a></p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>