import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { FeatureToggleGuardService } from '../services/feature-toggle-guard.service';
import { OrderService } from '../services/order.service';
import { AnalyticsService } from '../services/analytics.service';
@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.css']
})
export class AuthLoginComponent implements OnInit {
  form: UntypedFormGroup;
  public loginInvalid: boolean;
  public loading: boolean;
  public showSignup: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private featureToggleSvc: FeatureToggleGuardService,
    private orderSvc: OrderService,
    private gaSvc: AnalyticsService
  ) {

  }
  ngOnInit(): void {
    this.loading = false;
    this.getFeatureToggles();
    if (this.authService.isAuthenticated()) {
      this.navigateFromLogin();
    }
    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  getFeatureToggles() {
    this.showSignup = this.featureToggleSvc.enableRouteSignupUnauthenticated();
  }

  onSubmit() {
    this.loading = true;
    this.loginInvalid = false;
    const body = {
      username: this.form.get('username').value,
      password: this.form.get('password').value
    };
    this.authService.loginUser(body).subscribe(res => {
      this.authService.storeLoginResponse(body.username, res.body["token"], JSON.stringify(res.body["userpermissions"]));
      this.gaSvc.event('login');
      this.orderSvc.updateCartFromWithLatestProductData();
      this.navigateFromLogin();
    },
      () => {
        this.loginInvalid = true;
        this.loading = false;
      });
  }
  navigateFromLogin() {
    const subscriptionType = this.route.snapshot.queryParamMap.get('subscription');
    const returnUrl = this.route.snapshot.queryParamMap.get('return');
   if (subscriptionType == 'vendor') {
      this.router.navigate(['signup/vendor']);
    } else if (returnUrl?.length > 0) {
      this.router.navigateByUrl(returnUrl);
    } else if (this.authService.isCurrentUserVendorAdmin()) {
      this.router.navigate(['profile']);
    } else {
      this.router.navigate(['home']);
    }
  }
}
