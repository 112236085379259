import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'

import { environment } from '../../environments/environment'
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleGuardService implements CanActivate {

  constructor(
    private router: Router,
    private authSvc: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url = state.url;

    if (url.endsWith('/cart')) {
      if (!this.enableRouteCart()) {
        return this.router.createUrlTree(['/home']);
      }
    }
    if (url.endsWith('/about')) {
      if (!this.enableRouteAbout()) {
        return this.router.createUrlTree(['/home']);
      }
    }
    if (url.endsWith('/products')) {
      if ((!this.enableRouteProducts() && this.authSvc.isAuthenticated()) && !this.enableRouteProductsUnauthenticated()) {
        return this.router.createUrlTree(['/home']);
      }
    }
    if (url.includes('/search')) {
      if (!(this.enableRouteSearch() && this.authSvc.isAuthenticated()) && !this.enableRouteSearchUnauthenticated()) {
        return this.router.createUrlTree(['/home']);
      }
    }
    if (url.endsWith('/signup')) {
      if (this.authSvc.isAuthenticated() || !this.enableRouteSignupUnauthenticated()) {
        return this.router.createUrlTree(['/home']);
      }
    }
    if (url.includes('/signup/vendor')) {
      if (!this.enableRouteVendorSignup()) {
        return this.router.createUrlTree(['/home']);
      }
    }
    if (url.endsWith('/vendors')) {
      if (!this.enableRouteVendors()) {
        return this.router.createUrlTree(['/home']);
      }
    }
    return true;
  }
  enableFeaturedBusiness(): boolean {
    return environment.features.enableFeaturedBusiness === true;
  }
  enableFeaturedProductsOnHomeWhenUnauthenticated(): boolean {
    return environment.features.enableFeaturedProductsOnHomeWhenUnauthenticated === true;
  }
  enableFooterAddress(): boolean {
    return environment.features.enableFooterAddress === true;
  }
  enableOrderCustomerNotes(): boolean {
    return environment.features.enableOrderCustomerNotes === true;
  }
  enablePaymentProcessing(): boolean {
    return environment.features.enablePaymentProcessing === true;
  }
  enablePriceTiers(): boolean {
    return environment.features.enablePriceTiers === true;
  }
  enableProductAdd(): boolean {
    return environment.features.enableProductAdd === true;
  }
  enableProductCardVendorName(): boolean {
    return environment.features.enableProductCardVendorName === true;
  }
  enableProductDelete(): boolean {
    return environment.features.enableProductDelete === true;
  }
  enableProductEditingActive(): boolean {
    return environment.features.enableProductActiveEditing === true;
  }
  enableProductEditingDescription(): boolean {
    return environment.features.enableProductDescriptionEditing === true;
  }
  enableProductEditingImage(): boolean {
    return environment.features.enableProductImageEditing === true;
  }
  enableProductEditingName(): boolean {
    return environment.features.enableProductNameEditing === true;
  }
  enableProductEditingPricing(): boolean {
    return environment.features.enableProductPricingEditing === true;
  }
  enableProductEditingQuantity(): boolean {
    return environment.features.enableProductQuantityEditing === true;
  }
  enableProductEditingSKU(): boolean {
    return environment.features.enableProductSKUEditing === true;
  }
  enableProductFilters(): boolean {
    return environment.features.enableProductFilters === true;
  }
  enableProductFilterCategory(): boolean {
    return environment.features.enableProductFilterCategory === true;
  }
  enableProductFilterTag(): boolean {
    return environment.features.enableProductFilterTag === true;
  }
  enableProductFilterVendor(): boolean {
    return environment.features.enableProductFiltersVendor === true;
  }
  enableProductMultiplePrices(): boolean {
    return environment.features.enableProductMultiplePrices === true;
  }
  enableProfileCustomerOrdersTab(): boolean {
    return environment.features.enableProfileCustomerOrdersTab === true;
  }
  enableProfileOrderHistoryTab(): boolean {
    return environment.features.enableProfileOrderHistoryTab === true;
  }
  enableProfileVendorSalesReport(): boolean {
    return environment?.features?.enableProfileVendorSalesReport;
  }
  enableProfileVendorSkuSearchQuickEdit(): boolean {
    return environment.features.enableProfileVendorsQuickEditBySKU === true;
  }
  enableProfileVendorTaxes(): boolean {
    return environment.features.enableProfileVendorTaxes === true;
  }
  enableProfileVendorTaxEditing(): boolean {
    return environment.features.enableProfileVendorTaxEditing === true;
  }
  enableRouteCart(): boolean {
    return environment.features.enableRouteCart === true;
  }
  enableRouteAbout(): boolean {
    return environment.features.enableRouteAbout === true;
  }
  enableRouteProducts(): boolean {
    return environment.features.enableRouteProducts === true;
  }
  enableRouteProductsUnauthenticated(): boolean {
    return environment.features.enableRouteProductsUnauthenticated === true;
  }
  enableRouteSearch(): boolean {
    return environment.features.enableRouteSearch === true;
  }
  enableRouteSearchUnauthenticated(): boolean {
    return environment.features.enableRouteSearchUnauthenticated === true;
  }
  enableRouteSignupUnauthenticated(): boolean {
    return environment.features.enableRouteSignupUnauthenticated === true;
  }
  // enableRouteSignup(): boolean {
  //   return environment.features.enableRouteSignup === true;
  // }
  enableRouteVendors(): boolean {
    return environment.features.enableRouteVendors === true;
  }
  enableRouteVendorSignup(): boolean {
    return environment.features.enableRouteVendorSignup === true;
  }

  enableRouteLogin(): boolean {
    return environment.features.enableRouteLogin === true;
  }

  getCustomBannerText(): string {
    return environment?.features?.customBannerText ? environment?.features?.customBannerText : '';
  }
  getCustomBannerSubtitle(): string {
    return environment?.features?.customBannerSubtitle ? environment?.features?.customBannerSubtitle : '';
  }
  getCustomBannerSubtitle2(): string {
    return environment?.features?.customBannerSubtitle2 ? environment?.features?.customBannerSubtitle2 : '';
  }
  getCustomTitle(): string {
    return environment?.features?.customTitle ? environment.features.customTitle : '';
  }
  getBannerImage(): string {
    return environment?.features?.bannerImageURL ? environment?.features?.bannerImageURL : '';
  }
  getBannerImageSmall(): string {
    return environment?.features?.bannerImageSmallURL ? environment?.features?.bannerImageSmallURL : '';
  }
  getCustomCartDisclaimer(): string {
    return environment?.features?.customCartDisclaimer ? environment?.features?.customCartDisclaimer : '';
  }
}
