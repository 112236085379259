import { Component, Input, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FeatureToggleGuardService } from 'src/app/services/feature-toggle-guard.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  @Input() text: string;
  @Input() subtitle: string;
  @Input() subtitle2: string;

  bannerImageURL: string = ''

  constructor(
    private breakpointObserver: BreakpointObserver,
    private featureToggleSvc: FeatureToggleGuardService) {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
    ]).subscribe(result => {
      if (result.matches) {
        this.bannerImageURL = this.featureToggleSvc.getBannerImageSmall();
      } else {
        this.bannerImageURL = this.featureToggleSvc.getBannerImage();
      }
    });
  }

  ngOnInit(): void { }
}
