<div class="flex-col justify-center banner" [style.background-image]="'url('+bannerImageURL+')'">
    <div class="flex-col space-between items-center banner-overlay">
        <div class="flex-col justify-center items-center">
            <span class="banner-text banner-text-color mat-display-3" *ngIf="!text">
                Shop <span class="banner-text-alt-color">local.</span> Shop
                <span class="banner-text-alt-color">Okra.</span>
            </span>
            <span class="banner-text banner-text-color mat-display-3" *ngIf="text">{{text}}</span>
            <span class="banner-text-subtitle banner-text-color mat-display-1" *ngIf="subtitle">{{subtitle}}</span>
            <br>
        </div>

        <div class="flex-col justify-center items-center">
            <div><span class="banner-text-subtitle2 banner-text-alt-color mat-h2" *ngIf="subtitle2">{{subtitle2}}</span>
            </div>
            <div class="line-container">
                <div class="line-icon">
                    <mat-icon svgIcon="okra-outline"></mat-icon>
                </div>
            </div>
            <br>
        </div>

    </div>
</div>