<mat-toolbar>
  <mat-toolbar-row class="flex-row justify-start items-center flex-grow flex-sm-space">
    <div class="flex-row justify-start">
      <a name="homelogo" class="hide-sm" mat-icon-button routerLink="" id="okra-logo-button" (click)="onRouteClick()">
        <mat-icon svgIcon="okra-logo-text" id="okra-logo-icon"></mat-icon>
      </a>
      <a name="homelogo" class="hide-gt-sm" mat-icon-button routerLink="" id="okra-logo-button" (click)="onRouteClick()">
        <mat-icon svgIcon="okra-logo-no-text" id="okra-logo-icon"></mat-icon>
      </a>
      <div class="hide-sm">
        <div *ngIf="isAuthenticated">
          <a mat-button *ngFor="let item of authMenuItems" routerLink="{{item.route}}" (click)="onRouteClick()">
            {{item.label}}</a>
        </div>
        <div *ngIf="!isAuthenticated">
          <a mat-button *ngFor="let item of anonMenuItems" routerLink="{{item.route}}" (click)="onRouteClick()">
            {{item.label}}</a>
        </div>
      </div>
    </div>
    <div class="flex-grow hide-sm">
      <app-search-bar *ngIf="showSearchBar" #searchBar></app-search-bar>
    </div>

    <div id="right-items" class="flex-row">
      <a mat-button routerLink="/about" *ngIf="!isAuthenticated && showAbout">ABOUT OKRA</a>
      <div *ngIf="showLogin && showSignup">
        <button mat-button class="profile-btn" [matMenuTriggerFor]="profileDropdown">
          <div class="flex-row justify-center items-center">
            <mat-icon name="profile" class="nav-icon" id="profile-icon">person</mat-icon>
            <span class="hide-xs">{{currentUser?.firstname}}</span>
            <mat-icon class="nav-icon">arrow_drop_down</mat-icon>
          </div>
        </button>
      
        <mat-menu #profileDropdown="matMenu">
          <a mat-menu-item routerLink="/login" *ngIf="!isAuthenticated && showLogin">LOG IN</a>
          <a mat-menu-item *ngIf="isAuthenticated" (click)="navigateToProfile()">PROFILE</a>
          <a mat-menu-item *ngIf="isAuthenticated" (click)="logout()">LOG OUT</a>
          <a mat-menu-item routerLink="/signup" *ngIf="!isAuthenticated && showSignup">CREATE ACCOUNT</a>
        </mat-menu>
      </div>
      <div class="nav-cart" *ngIf="showCart && cartItemCount > 0">
        <a mat-icon-button routerLink="/cart" [matBadge]="cartItemCount" matBadgeColor="warn" matBadgeSize="medium">
          <mat-icon>shopping_cart</mat-icon>
          <span class="cdk-visually-hidden">
            Button with a shopping cart icon with overlaid badge showing {{cartItemCount}} as the number of items
            currently in the cart
          </span>
        </a>
      </div>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row class="flex-row justify-start items-center hide-gt-sm">
    <div>
      <button mat-icon-button class="nav-button" [matMenuTriggerFor]="dropMenu">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #dropMenu="matMenu">
        <ng-container>
          <a mat-menu-item routerLink="">HOME</a>
        </ng-container> 
        <div *ngIf="isAuthenticated">
          <ng-container *ngFor="let item of authMenuItems">
            <a mat-menu-item routerLink="{{item.route}}" (click)="onRouteClick()">
              {{item.label}}</a>
          </ng-container>
        </div>
        <div *ngIf="!isAuthenticated">
          <ng-container *ngFor="let item of anonMenuItems">
            <a mat-menu-item routerLink="{{item.route}}" (click)="onRouteClick()">
              {{item.label}}</a>
          </ng-container>
          <div>
            <a mat-menu-item routerLink='/login'>LOG IN</a>
          </div>
        </div>
      </mat-menu>
    </div>
    <div class="flex-grow" *ngIf="showSearchBar">
      <app-search-bar #searchBar></app-search-bar>
    </div>
  </mat-toolbar-row>
</mat-toolbar>