import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { ProductService } from "../services/product.service";
import { SearchResults } from "../models/searchResults";
import { FeatureToggleGuardService } from '../services/feature-toggle-guard.service';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchResultsComponent implements OnInit {
  loading: boolean;
  
  constructor(
    private route: ActivatedRoute,
    private featureToggleSvc: FeatureToggleGuardService,
    private router: Router,
    private productSvc: ProductService) { }

  ngOnInit() {
    this.loading = false;
  }
}
