import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js'
export interface Phone {
    number: string; // E164 format eg +15555555555
    regioncode: string; // 2 letter CLDR code
}

export function GetFormattedPhoneNumber(phone: Phone): string {
    if (phone && phone?.number) {
        const countryCode = phone?.regioncode ? phone.regioncode : 'US';
        const parsed = parsePhoneNumberFromString(phone.number, countryCode as CountryCode);
        if (parsed && parsed.isValid()) {
            return parsed.formatNational();
        }
    }
    return '';
}