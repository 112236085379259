import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
})
export class EmailVerificationComponent implements OnInit {

  public checkingValidation: boolean = true;
  public validationError: boolean = false;
  public errorMessage: string = '';
  constructor(private route: ActivatedRoute, private router: Router, private authSvc: AuthService) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.authSvc.verifyEmailConfirmation(id).subscribe(res => {
      this.router.navigate(['profile']);
    },
      err => {
        this.validationError = true;
        this.checkingValidation = false;

        this.errorMessage = 'The email link you followed could not be verified, it may have expired. Please log in and request a new confirmation email.';

      });
  }

}
