import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { OrderService } from '../../../../services/order.service';
import { ConvertToProductNumeric, NumericPriceSpecification, Product, ProductNumeric } from '../../../../models/product';
import { FeatureToggleGuardService } from '../../../../services/feature-toggle-guard.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ProductDetailsDialogComponent } from '../product-details-dialog/product-details-dialog.component';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.css']
})
export class ProductCardComponent implements OnInit {
  counter: number = 1;
  maxQuantity: number;
  prices: NumericPriceSpecification[] = [];
  @Input() product: Product;
  productNumeric: ProductNumeric;
  qty: UntypedFormControl = new UntypedFormControl(this.counter, [Validators.min(1)]);
  showVendorName: boolean = true;
  constructor(
    private dialog: MatDialog,
    private orderService: OrderService,
    private featureToggleSvc: FeatureToggleGuardService) { }

  ngOnInit(): void {
    this.productNumeric = ConvertToProductNumeric(this.product);
    this.maxQuantity = this.productNumeric.quantity;
    this.prices = this.productNumeric.prices;
    this.showVendorName = this.featureToggleSvc.enableProductCardVendorName();
  }


  addToCart() {
    this.productNumeric.quantity = this.counter;
    this.orderService.addToCart(this.productNumeric, this.maxQuantity);
  }

  addUnit() {
    this.counter < this.productNumeric.quantity ? this.counter += 1 : this.counter = this.productNumeric.quantity;
    this.qty.setValue(this.counter);
  }

  subtractUnit() {
    this.counter > 1 ? this.counter -= 1 : this.counter = 1;

    this.qty.setValue(this.counter);
  }

  onChangeQty(e: any) {
    let qty = this.qty.value;

    if (qty < 1) {
      this.counter = 1;
      this.qty.setValue(this.counter);
    } else if (qty > this.productNumeric.quantity) {
      this.counter = this.productNumeric.quantity;
      this.qty.setValue(this.productNumeric.quantity);
    } else {
      this.counter = this.qty.value
    }
  }

  showDetails(): void {
    const dialogRef = this.dialog.open(ProductDetailsDialogComponent, {
      data: { product: this.product },
    });

    dialogRef.afterClosed().subscribe(result => { });
  }
}
