import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Component({
  selector: 'app-auth-resetpassword',
  templateUrl: './auth-reset-password.component.html',
  styleUrls: ['./auth-reset-password.component.scss'],
})
export class AuthResetPasswordComponent implements OnInit {

  form: UntypedFormGroup;
  emailId: string;
  errorMessage = '';
  public loading: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.emailId = this.route.snapshot.paramMap.get('id');
    this.authService.verifyPasswordResetEmail(this.emailId).subscribe(res => {
    },
      err => {
        this.errorMessage = 'The email link you followed could not be verified, it may have expired. Please request another password reset link.';
      });

    this.loading = false;
    this.form = this.fb.group({
      password: ['', Validators.required],
      confirmPass: ['', Validators.required]
    });
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    this.loading = true;
    const pass = this.form.get('password').value;
    const confirmPass = this.form.get('confirmPass').value;

    if (pass !== confirmPass) {
      this.errorMessage = 'New passwords do not match. Please try again.';
      this.loading = false;
      return;
    }

    const body = {
      emailverification: {
        id: this.emailId
      },
      usercredentials: {
        userid: this.emailId,
        password: this.form.get('password').value
      }
    };

    this.authService.updatePassword(body).subscribe(res => {
      this.router.navigate(['login']);
    },
      () => {
        this.errorMessage = 'Unable to update password. Please try again.';
        this.loading = false;
      }
    ).add(() => {
    });
  }

}
