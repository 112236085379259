import { Component } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser'
import { MatIconRegistry } from '@angular/material/icon'
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { AuthService } from './services/auth.service';
import { FeatureToggleGuardService } from './services/feature-toggle-guard.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  destroyed = new Subject<void>();
  isAuthenticated = false;
  isMobile: boolean = false;
  navigationSubscription: Subscription;
  showFooter: boolean = true;
  showFooterMobile: boolean = true;
  showFooterWeb: boolean = true;

  title = 'Okra Beta';
  constructor(
    private breakpointObserver: BreakpointObserver,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private router: Router,
    private titleSvc: Title,
    private authSvc: AuthService,
    private featureToggleSvc: FeatureToggleGuardService,
  ) {
    this.matIconRegistry.addSvgIcon(
      "okra-logo-text",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/okra-logo-text.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "okra-logo-no-text",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/okra-logo-no-text.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "okra-logo-text-alt",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/okra-logo-text-alt.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "okra-outline",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/okra-outline.svg")
    );

    this.navigationSubscription = this.router.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        this.isAuthenticated = this.authSvc.isAuthenticated();
      };
      if (ev instanceof RoutesRecognized) {
        const showFooterWeb = ev.state.root.firstChild.data['showFooter'];
        if (showFooterWeb !== undefined) {
          this.showFooterWeb = showFooterWeb;
        } else {
          this.showFooterWeb = true;
        }
        const showFooterMobile = ev.state.root.firstChild.data['showFooterMobile'];
        if (showFooterMobile !== undefined) {
          this.showFooterMobile = showFooterMobile;
        } else {
          this.showFooterMobile = this.showFooterWeb;
        }
        this.showFooter = this.isMobile ? this.showFooterMobile : this.showFooterWeb;
      }
    });

    this.breakpointObserver.observe([Breakpoints.XSmall]).pipe(takeUntil(this.destroyed)).subscribe(result => {
      if (result.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      this.showFooter = this.isMobile ? this.showFooterMobile : this.showFooterWeb;
    });

    const title = this.featureToggleSvc.getCustomTitle();
    if (title) {
      this.titleSvc.setTitle(title);
    }
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    this.destroyed.next();
    this.destroyed.complete();
  }
}
