import { Phone } from "./phone";
import { Product } from "./product";
import { VendorLink } from "./vendor";


export interface CustomerLink {
    userid: string;
    externalid?: string;
    firstname: string;
    lastname: string;
    email: string;
    pricetier?: string;
    phone?: Phone;
}

export interface LineItem {
    product: Product;
    linetotal: string;
    alloworderswithoutpayment: boolean;
}

export interface Order {
    id: string;
    customer: CustomerLink;
    vendor: VendorLink;
    createdat: number;
    acceptedat: number;
    closedat: number;
    cancelledat: number;
    lineitems: LineItem[];
    subtotal: string;
    totaltax: string;
    total: string;
    paymentstatus: string;
    paymentprocessingfee: string;
    customernotes?: string;
}

// to be used for http GET /orders/search query filters
export enum OrderStatusFilter {
    Any = "any",
    Open = "open",
    Closed = "closed",
    Cancelled = "cancelled",
}

// to be used for setting order.paymentstatus value
export enum PaymentStatus {
    Unpaid = "unpaid",
    PartiallyPaid = "partially_paid",
    Paid = "paid",
    Partially_Refunded = "partially_refunded",
    Refunded = "refunded"
}

// To be used for http GET /orders/search query filters
export enum PaymentStatusFilter {
    Any = "any",
    Unpaid = "unpaid",
    PartiallyPaid = "partially_paid",
    Paid = "paid",
    Partially_Refunded = "partially_refunded",
    Refunded = "refunded"
}
