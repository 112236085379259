<div class="flex-col flex-grow">
  <div *ngIf="loadingV" class="flex-col justify-start items-center flex-grow">
    <h3>{{loadingMessage}}</h3>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div *ngIf="!loadingV" class="flex-col hide-xs flex-grow">
    <app-vendor-card [collapsible]="true" [vendor]="vendor" [detailView]="true">
    </app-vendor-card>
    <app-products [vendorId]="id" title="" searchContext="vendor" class="flex-col flex-grow"></app-products>
  </div>
  <div *ngIf="!loadingV" class="flex-col space-between hide-gt-sm flex-grow">
    <div *ngIf="page === 'shop'" class="flex-col flex-grow">
      <app-products *ngIf="vendor" [vendorId]="id" title="Shop at {{vendor?.name}}" searchContext="vendor">
      </app-products>
      <div *ngIf="!loadingV && !vendor">
        <div *ngIf="vendor?.name" class="section-subtitle">Shop at {{vendor?.name}}</div>
        <div class="card-title-sm ml-10 mr-10">There are currently no products for sale at this vendor.</div>
      </div>
    </div>
    <app-vendor-card *ngIf="page !== 'info'" [vendor]="vendor" [detailView]="true">
    </app-vendor-card>
  </div>
</div>