<div class="flex-col flex-grow">
  <div class="flex-row justify-start items-center" class="mb-10">
    <button *ngIf="enableFilters" class="filterButton hide-gt-sm" mat-mini-fab color="primary" aria-label="Toggle filters"
      (click)="toggleFilterNav()">
      <mat-icon>filter_alt</mat-icon>
    </button>
    <div *ngIf="title" class="flex-1 section-subtitle">{{title}}</div>
  </div>
  <mat-sidenav-container class="flex-col flex-grow">
    <mat-sidenav #filterNav class="filterContainer" [opened]="mobileQuery.matches ? false : true" 
    [mode]="mobileQuery.matches ? 'over' : 'side'" *ngIf="enableFilters">
      <div class="flex-col justify-start items-center">
        <div style="width:90%;padding-top: 15px">
          <form (ngSubmit)="onSubmit()" *ngIf="searchContext !== 'product'">
            <mat-form-field floatLabel="never" class="search-bar">
              <input matInput type="text" autocomplete="off" #searchInput [formControl]="searchCtrl"
                [placeholder]="showContext()" />
              <button type="button" mat-icon-button matSuffix *ngIf="keywords.length > 0" type="button"
                (click)="clearProductSearchBar()">
                <mat-icon>clear</mat-icon>
              </button>
              <button type="button" mat-icon-button matSuffix (click)="onSubmit()">
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
          </form>
        </div>
        <h2 style="padding-top: 15px">Filters</h2>
        <div class="flex-col justify-start items-center">
          <mat-form-field *ngIf="enableFilterVendor">
            <mat-label> Vendor Name</mat-label>
            <mat-select [formControl]="filterVendorCtrl" #vendorList multiple
              (closed)="mobileQuery.matches ? toggleFilterNav() : null" (selectionChange)="filterByVendor($event)">
              <mat-option *ngFor="let vendor of filterVendors | async" [value]="vendor"> {{vendor.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="enableFilterCategory">
            <mat-label> Categories</mat-label>
            <mat-select #categoryList [formControl]="filterCategoryCtrl" multiple
              (closed)="mobileQuery.matches ? toggleFilterNav() : null"
              (selectionChange)="onFilterCategoryChanged($event)">
              <mat-option *ngFor="let cat of filterCategories" [value]="cat"> {{cat}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="flex-row justify-center items-center" class="mb-20">
            <button mat-raised-button id="reset-button" (click)="resetFilterandSearch()">Clear All</button>
          </div>
        </div>
      </div>
    </mat-sidenav>

    <mat-sidenav-content class="flex-col flex-grow">
      <div class="flex-col justify-center items-center" *ngIf="loading">
        <h3>Loading products...</h3>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
      <div #divContent>
        <div class="card-title-sm flex-col justify-center items-center" *ngIf="!loading && !products?.length">No products match your
          search. Please try different terms or filters.
        </div>
        <virtual-scroller id="allProducts" #scroll [items]="products" [parentScroll]="divContent"
          (vsEnd)="fetchMore($event)">
          <app-product-card *ngFor="let product of scroll.viewPortItems; let i = index; trackBy: myTrackByFunction"
            [product]="product"></app-product-card>
        </virtual-scroller>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>