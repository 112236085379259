import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/legacy-dialog";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AboutComponent } from './about/about.component';
import { AboutSalesDialogComponent } from './about-sales-dialog/about-sales-dialog.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthLoginComponent } from './auth-login/auth-login.component';
import { AuthRequestPasswordResetComponent } from './auth-reset/auth-request-password-reset.component';
import { AuthResetPasswordComponent } from './auth-resetpassword/auth-reset-password.component'
import { BannerComponent } from './modules/shared/components/banner/banner.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CustomerDetailsDialogComponent } from './customer-details-dialog/customer-details-dialog.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { EventTextPipe } from './pipes/event-text.pipe';
import { FeatureBusinessComponent } from './feature-business/feature-business.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './views/home/home.component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { NavHeaderComponent } from './nav-header/nav-header.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ProductDetailComponent } from './views/product-detail/product-detail.component';
import { ProductsComponent } from './products/products.component';

import { SearchBarComponent } from './search-bar/search-bar.component'
import { SearchResultsComponent } from './search-results/search-results.component';
import { SharedModule } from './modules/shared/shared.module';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { UrlLookupComponent } from './url-lookup/url-lookup.component';
import { VendorDetailComponent } from './views/vendor-detail/vendor-detail.component';
import { ViewVendorsComponent } from './views/view-vendors/view-vendors.component';

@NgModule({
  declarations: [
    AboutComponent,
    AboutSalesDialogComponent,
    AppComponent,
    AuthLoginComponent,
    AuthRequestPasswordResetComponent,
    AuthResetPasswordComponent,
    BannerComponent,
    ContactUsComponent,
    CustomerDetailsDialogComponent,
    EmailVerificationComponent,
    EventTextPipe,
    FeatureBusinessComponent,
    FooterComponent,
    HomeComponent,
    ImageUploaderComponent,
    NavHeaderComponent,
    NotFoundComponent,
    ProductDetailComponent,
    ProductsComponent,
    SearchBarComponent,
    SearchResultsComponent,
    UrlLookupComponent,
    VendorDetailComponent,
    ViewVendorsComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    MatNativeDateModule,
    SharedModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptorService,
    multi: true
  },
  { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { panelClass: 'cdk-overlay-pane-override', hasBackdrop: true } }],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
