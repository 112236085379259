import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as currency from 'currency.js';

import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { AnalyticsService } from './analytics.service';
import { FeatureToggleGuardService } from './feature-toggle-guard.service';
import { ProductService } from './product.service';
import { CustomerLink, LineItem, Order, PaymentStatus } from '../models/order';
import { ConvertToProduct, ConvertToProductNumeric, Product, ProductNumeric } from '../models/product';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  cartChangeCompleted = new Subject<boolean>();
  cartItemCount$ = new BehaviorSubject<number>(0);
  cartItems = new Set<LineItem>();

  private vendorOrders: Order[];

  constructor(
    private http: HttpClient,
    private authSvc: AuthService,
    private gaSvc: AnalyticsService,
    private featureToggleSvc: FeatureToggleGuardService,
    private productSvc: ProductService,) {
    this.getCartItemsFromStorage();
    this.cartChangeCompleted.next(true);
    this.cartItemCount$.next(this.getCartItemCount());
  }

  private getCartItemsFromStorage() {
    this.cartItems = new Set<LineItem>();
    if (localStorage.getItem('cartItems') !== null) {
      const parsedData = JSON.parse(localStorage.getItem('cartItems')) || [];
      this.cartItems = new Set<LineItem>(parsedData);
    }
  }

  private putCartItemsInStorage() {
    const cartArray = Array.from(this.cartItems);
    localStorage.setItem('cartItems', JSON.stringify(cartArray));
  }

  public calculateLineTaxes(p: Product) {
    let itemTax = currency(0);
    for (const key in p.taxes) {
      const taxData = p.taxes[key];

      if (taxData.active && !taxData.inclusive) {
        const taxPercentage = Number(taxData.percentage) / 100;
        const price = currency(p.prices[0].price);
        const quantity = Number(p.quantity);
        const linePrice = price.multiply(quantity);
        const lineTax = linePrice.multiply(taxPercentage);
        itemTax = itemTax.add(lineTax.value);
      }
    }

    return itemTax;
  }

  private generateOrder(customerLink: CustomerLink, customerNotes = '') {
    this.vendorOrders = [];
    this.getCartItemsFromStorage();
    const cartArray = Array.from(this.cartItems);
    let vendorLine = [];
    for (let i = 0; i < cartArray.length; i++) {

      if (!vendorLine[cartArray[i].product.vendor.id])
        vendorLine[cartArray[i].product.vendor.id] = [];

      vendorLine[cartArray[i].product.vendor.id].push(cartArray[i]);

      vendorLine = cartArray.reduce((r, a) => {
        r[a.product.vendor.id] = r[a.product.vendor.id] || [];
        r[a.product.vendor.id].push(a);
        return r;
      }, Object.create(null));
    }

    const createdAt = Math.round(new Date().getTime() / 1000);

    for (const key in vendorLine) {
      const lineItems = vendorLine[key] as LineItem[];

      let vendorSubTotal = currency(0);
      let vendorTaxes = currency(0);
      const enableTaxes = this.featureToggleSvc.enableProfileVendorTaxes()
      for (let i = 0; i < lineItems.length; i++) {
        const price = currency(lineItems[i].product.prices[0].price);
        const quantity = Number(lineItems[i].product.quantity);
        const itemSubTotal = price.multiply(quantity);
        vendorSubTotal = vendorSubTotal.add(itemSubTotal);
        if (enableTaxes) {
          const lineTaxes = this.calculateLineTaxes(lineItems[i].product);
          vendorTaxes = vendorTaxes.add(lineTaxes);
        }
      }


      const vendorTotalDollars = vendorSubTotal.add(vendorTaxes);

      const order: Order = {
        id: null,
        customer: customerLink,
        vendor: lineItems[0].product.vendor,
        lineitems: lineItems,
        paymentstatus: PaymentStatus.Unpaid,
        createdat: createdAt,
        acceptedat: null,
        closedat: null,
        cancelledat: null,
        paymentprocessingfee: null,
        subtotal: String(vendorSubTotal),
        totaltax: String(vendorTaxes),
        total: String(vendorTotalDollars),
      };

      if (this.featureToggleSvc.enableOrderCustomerNotes() && customerNotes) {
        order.customernotes = customerNotes;
      }
      this.vendorOrders.push(order);
    }
  }

  processOrderFromCart(customerLink: CustomerLink, pcustomerNotes = '') {
    this.generateOrder(customerLink, pcustomerNotes);
    const payload = this.vendorOrders;
    return this.http.post(environment.apiGateway + '/orders', payload, { observe: 'response' });
  }

  addToCart(p: ProductNumeric, maxQuantity: number, notifyChanges = true) {
    if (notifyChanges === true) {
      this.cartChangeCompleted.next(false);
    }
    this.getCartItemsFromStorage();

    this.cartItems.forEach((item) => {
      if (item.product.id === p.id) {
        const existingItemProductNumeric = ConvertToProductNumeric(item.product);
        p.quantity = p.quantity + existingItemProductNumeric.quantity;

        this.cartItems.delete(item);
      }
    });

    if (p.quantity > maxQuantity)
      p.quantity = maxQuantity;

    const product = ConvertToProduct(p);
    const totalDollars = this.calculateItemPrice(p);
    const lineItem: LineItem = { product: product, linetotal: String(totalDollars), alloworderswithoutpayment: true };
    this.cartItems.add(lineItem);

    this.putCartItemsInStorage();
    this.gaSvc.event('add_to_cart', product);
    if (notifyChanges === true) {
      this.cartChangeCompleted.next(true);
      this.cartItemCount$.next(this.getCartItemCount());
    }
  }

  updateItemQuantity(productID: string, value: number, maxQuantity: number, notifyChanges = true) {
    if (notifyChanges === true) {
      this.cartChangeCompleted.next(false);
    }
    this.getCartItemsFromStorage();

    let selectedItem;
    this.cartItems.forEach((item) => {
      if (item.product.id === productID) {
        selectedItem = item;
        return;
      }
    });

    if (!selectedItem)
      return;

    const productNumeric = ConvertToProductNumeric(selectedItem.product);
    this.cartItems.delete(selectedItem);

    value <= maxQuantity ? productNumeric.quantity = value : productNumeric.quantity = maxQuantity;

    const totalDollars = this.calculateItemPrice(productNumeric);
    const product = ConvertToProduct(productNumeric);
    const lineItem: LineItem = { product: product, linetotal: String(totalDollars), alloworderswithoutpayment: true };
    this.cartItems.add(lineItem);

    this.putCartItemsInStorage();
    if (notifyChanges === true) {
      this.cartChangeCompleted.next(true);
      this.cartItemCount$.next(this.getCartItemCount());
    }
  }

  calculateItemPrice(p: ProductNumeric) {
    const price = p.prices[0].price;
    const quantity = p.quantity;
    const totalItemPrice = price * quantity;
    const totalDollars = currency(totalItemPrice);
    return totalDollars.value;
  }

  removeFromCart(p: ProductNumeric, notifyChanges = true) {
    if (notifyChanges === true) {
      this.cartChangeCompleted.next(false);
    }
    this.getCartItemsFromStorage();

    this.cartItems.forEach((item) => {
      if (item.product.id === p.id) {
        this.cartItems.delete(item);
        this.gaSvc.event('remove_from_cart', item.product);
      }
    });

    this.putCartItemsInStorage();
    if (notifyChanges === true) {
      this.cartChangeCompleted.next(true);
      this.cartItemCount$.next(this.getCartItemCount());
    }
  }

  getItems() {
    this.getCartItemsFromStorage();

    const cartArray = Array.from(this.cartItems);
    cartArray.sort((a, b) => (a.product.name > b.product.name) ? 1 : (a.product.name === b.product.name) ?
      ((a.product.vendor > b.product.vendor) ? 1 : -1) : -1);

    return cartArray;
  }

  private getCartItemCount(): number {
    let cartCount = 0;
    this.cartItems.forEach((item) => {
      cartCount += Number(item.product.quantity);
    });

    return cartCount;
  }

  clearCart(notifyChanges = true) {
    if (notifyChanges === true) {
      this.cartChangeCompleted.next(false);
    }
    this.vendorOrders = null;
    this.cartItems.clear();
    localStorage.removeItem('cartItems');
    if (notifyChanges === true) {
      this.cartChangeCompleted.next(true);
      this.cartItemCount$.next(0);
    }
  }

  public getUserOrders(userID: string): Observable<Order[]> {
    return this.http.get<Order[]>(`${environment.apiGateway}/users/${userID}/orders`);
  }

  public getVendorOrders(vendorID: string, skip: number, limit: number): Observable<Order[]> {
    let params = new HttpParams();
      params = params.set("skip", skip.toString());
      params = params.set("limit", limit.toString());
    
    const paramString = params.toString();

    let fullQueryUrl = environment.apiGateway + '/orders/search?vendor_id=' + vendorID;

    if (paramString.length > 0) {
      fullQueryUrl = `${fullQueryUrl}&${paramString}`;
    }
    return this.http.get<Order[]>(fullQueryUrl);
  }

  public getOrder(orderID: string): Observable<Order> {
    return this.http.get<Order>(`${environment.apiGateway}/orders/${orderID}`);
  }

  public updateCartFromWithLatestProductData() {
    const cartItems = this.getItems();
    if (!(cartItems.length > 0)) {
      return;
    }
    this.cartChangeCompleted.next(false);
    const observables$: Observable<Product>[] = cartItems.map(item => this.productSvc.getProduct(item.product.id).pipe(
      catchError(err => of(null))
    ));
    forkJoin(observables$).subscribe(res => {
      this.clearCart(false);
      // remove the nulls from errors finding products above
      const updatedProducts = res.filter(item => item);
      if (cartItems.length !== updatedProducts.length) {
        console.warn(`Updating cart items; could not find ${cartItems.length - updatedProducts.length} products, removing them from cart!`);
      }
      updatedProducts.forEach(p => {
        const maxQty = ConvertToProductNumeric(p).quantity;
        p.quantity = cartItems.find(item => item.product.id === p.id).product.quantity
        this.addToCart(ConvertToProductNumeric(p), maxQty, false);
      });
      this.cartChangeCompleted.next(true);
    });
  }

  public deleteUnpaidUserOrders(checkoutSessionID: string, userID?: string) {
    let uID = '';
    if (userID) {
      uID = userID;
    } else {
      uID = this.authSvc.getCurrentUserId();
    }
    if (!uID) {
      return of(null);
    }
    return this.http.delete(
      `${environment.apiGateway}/users/${uID}/orders?paymentstatus=unpaid&checkoutsessionid=${checkoutSessionID}`,
      { observe: "response" });
  }
}
