<div class="flex-col flex-grow">
  <div class="section-subtitle">Vendors</div>
  <div *ngIf="loading" class="flex-col justify-start items-center">
    <h3>Loading vendors...</h3>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div #scrollDiv class="flex-row flex-grow justify-center">
    <div class="card-title-sm" *ngIf="!loading && !vendors?.length">Oops - we don't seem to have anything
      here yet!</div>
    <virtual-scroller id="vendorsList" #scroll [items]="vendors" [parentScroll]="scrollDiv">
      <app-vendor-card *ngFor="let item of scroll.viewPortItems; let i = index; trackBy: myTrackByFunction"
        [vendor]="item" [detailView]="false"></app-vendor-card>
    </virtual-scroller>
  </div>
</div>