import { ActivatedRouteSnapshot, DetachedRouteHandle, ExtraOptions, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { Injectable, NgModule } from '@angular/core';

import { AboutComponent } from './about/about.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthLoginComponent } from './auth-login/auth-login.component';
import { AuthResetPasswordComponent } from './auth-resetpassword/auth-reset-password.component';
import { AuthRequestPasswordResetComponent } from './auth-reset/auth-request-password-reset.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { FeatureToggleGuardService } from './services/feature-toggle-guard.service';
import { HomeComponent } from './views/home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ProductDetailComponent } from './views/product-detail/product-detail.component';
import { ProductsComponent } from './products/products.component';
import { SearchResultsComponent } from "./search-results/search-results.component";
import { UrlLookupComponent } from './url-lookup/url-lookup.component';
import { VendorDetailComponent } from './views/vendor-detail/vendor-detail.component';
import { ViewVendorsComponent } from './views/view-vendors/view-vendors.component';


const routes: Routes = [
  {
    path: '', component: HomeComponent, runGuardsAndResolvers: 'always',
    data: { showFooter: true },
  },
  {
    path: '404', redirectTo: '404.html'
  },
  {
    path: '404.html', component: NotFoundComponent, runGuardsAndResolvers: 'always',
    data: { showFooter: true },
  },
  {
    path: 'about', component: AboutComponent, pathMatch: "full", canActivate: [FeatureToggleGuardService],
    data: { showFooter: true }
  },
  {
    path: 'cart', loadChildren: () => import('./cart/cart.module').then(m => m.CartModule),
    data: { showFooter: true },
  },
  {
    path: 'signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule),
    data: { showFooter: true },
  },
  {
    path: 'home', redirectTo: '',
    data: { shouldReuse: true, showFooter: true },
  },
  {
    path: 'legal',
    loadChildren: () => import('./legal/legal.module').then(m => m.LegalModule),
    data: { showFooter: true },
  },
  {
    path: 'profile', 
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule), 
    canActivate: [AuthGuardService]
  },
  {
    path: 'login', component: AuthLoginComponent,
    data: { showFooter: true },
  },
  {
    path: 'reset_password', component: AuthRequestPasswordResetComponent,
    data: { showFooter: true },
  },
  {
    path: 'reset_password/:id', component: AuthResetPasswordComponent,
    data: { showFooter: true },
  },
  {
    path: 'products', component: ProductsComponent, pathMatch: 'full', canActivate: [FeatureToggleGuardService],
    data: { showFooter: false },
  },
  {
    path: 'products/:id', component: ProductDetailComponent, canActivate: [AuthGuardService],
    data: { showFooter: true },
  },
  {
    path: 'search', component: SearchResultsComponent, canActivate: [FeatureToggleGuardService],
    data: { showFooter: false },
  },
  {
    path: 'vendors', component: ViewVendorsComponent, canActivate: [FeatureToggleGuardService, AuthGuardService],
    data: { showFooter: false },
  },
  {
    path: 'vendors/:id', component: VendorDetailComponent,
    data: { showFooter: false },
  },
  {
    path: 'verify_email/:id', component: EmailVerificationComponent,
    data: { showFooter: true },
  },
  {
    path: ':url', component: UrlLookupComponent, pathMatch: 'full', canActivate: [FeatureToggleGuardService],
    data: { showFooter: false },
  },
  {
    path: '**', redirectTo: ''
  }
];
const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled'
};

@Injectable()
export class ReloadComponentRouteReuseStrategy extends RouteReuseStrategy {
  handlers: { [key: string]: DetachedRouteHandle } = {};
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data.shouldReuse || false;
  }
  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
    if (route.data.shouldReuse) {
      this.handlers[route.routeConfig.path] = detachedTree;
    }
  }
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!route.routeConfig && !!this.handlers[route.routeConfig.path];
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (!route.routeConfig) return null;
    return this.handlers[route.routeConfig.path];
  }
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.data.shouldReuse || false;
  }
}

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
  providers: [{ provide: RouteReuseStrategy, useClass: ReloadComponentRouteReuseStrategy }]
})
export class AppRoutingModule { }
