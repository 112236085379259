import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OkraUrl } from '../models/url';

@Injectable({
  providedIn: 'root'
})
export class UrlLookupService {

  baseUrl = `${environment.apiGateway}/url`;
  constructor(private http: HttpClient) { }

  lookup(url: string): Observable<OkraUrl> {
    return this.http.get<OkraUrl>(`${this.baseUrl}/${url}`);
  }

  reverseLookup(marketid?: string, vendorid?: string): Observable<OkraUrl> {
    if (marketid && vendorid)
      return;
    let params = new HttpParams();
    if (marketid) {
      params = params.set("marketid", marketid);
    }
    if (vendorid) {
      params = params.set("vendorid", vendorid);
    }
    const paramString = params.toString();
    if (paramString.length > 0) {
      return this.http.get<OkraUrl>(`${this.baseUrl}/search?${paramString}`);
    }
  }
}
