import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Product } from '../models/product';
import { SearchResults } from "../models/searchResults";

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private productsUrl = environment.apiGateway + '/products';

  constructor(private http: HttpClient) { }

  searchProducts(queryParm = '', activeOnly: boolean = true, skip?: number, limit?: number, vendorid?: string, tags?: string): Observable<SearchResults> {
    let params = new HttpParams();
    if (queryParm.length > 0) {
      params = params.set("q", queryParm);
    }
    if (activeOnly === false) {
      params = params.set("activeOnly", String(activeOnly));
    }
    if (skip > 0) {
      params = params.set("skip", skip.toString());
    }
    if (limit > 0) {
      params = params.set("limit", limit.toString());
    }
    if (vendorid?.length > 0) {
      params = params.set("vendorid", vendorid);
    }
    if (tags?.length > 0) {
      params = params.set("tags", tags);
    }
    const paramString = params.toString();
    let queryUrl = `${this.productsUrl}/search`;
    if (paramString.length > 0) {
      queryUrl = `${queryUrl}?${paramString}`;
    }
    return this.http.get<SearchResults>(queryUrl).pipe(
      tap(res => {
        if (!queryParm && res.products) {
          res.products.sort(this.sortByNameThenVendor);
        }
      })
    );
  }

  sortByName = function (a: Product, b: Product): number {
    return a?.name?.toLocaleLowerCase() > b?.name?.toLocaleLowerCase() ? 1 : -1;
  }

  sortByNameThenVendor = function (a: Product, b: Product): number {
    const nameA = a.name.toLocaleLowerCase();
    const nameB = b.name.toLocaleLowerCase();
    return (nameA > nameB) ? 1 : (nameA === nameB) ? ((a.vendor.name.toLocaleLowerCase() > b.vendor.name.toLocaleLowerCase()) ? 1 : -1) : -1;
  }

  getProduct(id): Observable<Product> {
    const productIdUrl = `${this.productsUrl}/${id}`;
    return this.http.get<Product>(productIdUrl);
  }

  updateProduct(id, payload, vendorID) {
    const options = {
      headers: new HttpHeaders({
        'Okra-Vendor-ID': vendorID
      })
    };
    return this.http.patch<Product>(`${this.productsUrl}/${id}`, payload, options);
  }

  addProduct(payload) {
    const options = {
      headers: new HttpHeaders({
        'Okra-Vendor-ID': (payload as Product)?.vendor?.id
      })
    };
    return this.http.post<Product>(this.productsUrl, payload, options);
  }

  deleteProduct(id, vendorID) {
    const options = {
      headers: new HttpHeaders({
        'Okra-Vendor-ID': vendorID
      })
    };
    return this.http.delete(`${this.productsUrl}/${id}`, options);
  }

  getFeaturedProducts(vendorID?: string): Observable<SearchResults> {
    let url = `${this.productsUrl}/featured`;
    let params = new HttpParams();
    if (vendorID && vendorID.length > 0) {
      params = params.set("vendorid", vendorID);
    }

    return this.http.get<SearchResults>(url, { params: params });
  }

  deleteFeaturedProduct(productID: string, vendorID: string) {
    const headers = new HttpHeaders({
      'Okra-Vendor-ID': vendorID
    })
    let params = new HttpParams();
    params = params.set("productid", productID);
    return this.http.delete(`${this.productsUrl}/featured`, { headers: headers, observe: 'response', params: params });
  }

  getProductCategories() {
    return environment.features.customProductCategories.sort();
  }

}
