/** This service should be used in angular.json file replacements for build configurations we do not want analytics tracking  */

import { Injectable } from '@angular/core';

//declare let gtag: Function;


@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }

  public event(type: string, payload?: any) {
    // Analytics service running without logging, unsure what debugging/breakpoints will do?
    // console.debug(`Analytics Service disabled without logging - event(type, payload) was called:`, type, payload);
    // gtag('event', type, payload);
  }
}
