import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlLookupService } from '../services/url-lookup.service';

@Component({
  selector: 'app-url-lookup',
  templateUrl: './url-lookup.component.html',
  styleUrls: ['./url-lookup.component.css']
})
export class UrlLookupComponent implements OnInit {

  loading: boolean = false;
  marketId: string = '';
  vendorId: string = '';
  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private urlLookupSvc: UrlLookupService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    let url = this.route.snapshot.paramMap.get('url');
    this.urlLookupSvc.lookup(url).subscribe(res => {
      if (!res) {
        return;
      }
      if (res.marketid) {
        this.marketId = res.marketid;
      } else if (res.vendorid) {
        this.vendorId = res.vendorid;
      }
      this.location.replaceState(res.url);
      this.loading = false;
    }, () => {
      this.router.navigate(['/404']);
    });
  }

}
