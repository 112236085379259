<div class="featured-business-container flex-row flex-col-sm">
  <div class="flex-col justify-center">
    <img [src]="featured.imagepath" alt="no image" id="img-logo">
  </div>
  <div class="featured-details-container flex-col space-evenly items-start">
    <span class="featured-type mat-display-2" *ngIf="featured.type">New {{featured.type}}!</span>
    <h1 class="featured-name" *ngIf="featured.name">{{featured.name}}</h1>
    <div class="featured-description" *ngIf="featured.description">{{featured.description}}</div>
    <button mat-raised-button color="white" *ngIf="featured.link" [routerLink]="featured.link">Learn More</button>
  </div>
</div>
