import { Component, OnInit } from '@angular/core';

interface FeaturedBusiness {
  type: string;
  imagepath: string;
  name: string;
  description: string;
  link: string;
}

@Component({
  selector: 'app-feature-business',
  templateUrl: './feature-business.component.html',
  styleUrls: ['./feature-business.component.css'],
})
export class FeatureBusinessComponent implements OnInit {

  featured: FeaturedBusiness = {
    type: '',
    imagepath: '',
    name: '',
    description: '',
    link: '',
  };

  constructor() { }

  ngOnInit() {
    this.featured = {
      type: 'Vendor',
      name: 'Houston Honey House',
      imagepath: 'https://aws-sam-cli-managed-default-samclisourcebucket-qkea04x1hu0h.s3.amazonaws.com/Okra/stock-images/vendors/houston-honey-house.png',
      description: 'Houston Honey House creates artisan wildflower honey products from bees that live near Houston, Spring and Conroe TX. Local honey has been shown to have many health benefits, including helping with seasonal allergies (year-round in Houston!), acting as a natural antiseptic, and soothing symptoms of sore throats, colds and the flu.',
      link: '/vendors/5f245377c3319062918e13aa'
    }
  }

}
