import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { AuthService } from 'src/app/services/auth.service';
import { ProductService } from '../../services/product.service';
import { Product } from '../../models/product';
import { FeatureToggleGuardService } from 'src/app/services/feature-toggle-guard.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  isAuthenticated: boolean = false;
  customBannerText: string = '';
  customBannerSubtitle: string = '';
  customBannerSubtitle2: string = '';
  showFeaturedBusiness: boolean = false;
  showFeaturedProductsLoading: boolean = true;
  showMap: boolean = true;
  constructor(
    private dialog: MatDialog,
    private authSvc: AuthService,
    private featureToggleSvc: FeatureToggleGuardService,
    private productService: ProductService,
    private router: Router) { }
    public products: Product[] = []
  ngOnInit(): void {
    this.isAuthenticated = this.authSvc.isAuthenticated();
    this.getFeatureToggles();
    
    this.showFeaturedProductsLoading = true;
    this.productService.getFeaturedProducts().subscribe(res => {
      this.products = res.products;
      this.showFeaturedProductsLoading = false;
    });
  }

  getFeatureToggles(): void {
    this.customBannerText = this.featureToggleSvc.getCustomBannerText();
    this.customBannerSubtitle = this.featureToggleSvc.getCustomBannerSubtitle();
    this.customBannerSubtitle2 = this.featureToggleSvc.getCustomBannerSubtitle2();
    this.showFeaturedBusiness = this.featureToggleSvc.enableFeaturedBusiness();
  }

  public myTrackByFunction(index: number, product: Product): string {
    return product.id;
  }

  public seeMoreProducts(){
    this.router.navigate(['products']);
  }
}
