import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';

import { environment } from '../../environments/environment';
import { UserPermissions } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private refreshTokenTimeout;

  constructor(private http: HttpClient, private router: Router) {

    if (this.isAuthenticated()) {
      this.startRefreshTokenTimer();
    }
  }

  loginUser(body) {
    return this.http.post(environment.apiGateway + '/auth', body, { observe: 'response' });
  }

  requestPasswordReset(body) {
    return this.http.post(environment.apiGateway + '/auth/password_reset/confirmation', body);
  }

  updatePassword(body) {
    const fullQueryUrl = environment.apiGateway + '/auth/password_reset';
    return this.http.post(fullQueryUrl, body);
  }

  storeLoginResponse(username: string, token: string, permissions: string) {
    localStorage.setItem('token', token);
    localStorage.setItem('permissions', permissions);
    localStorage.setItem('username', username);
    this.startRefreshTokenTimer();
  }

  registerUser(body) {
    return this.http.post(environment.apiGateway + '/auth/signup', body, { observe: 'response' });
  }

  isAuthenticated() {
    const jwt = this.getJwt();
    const isValid = this.isJwtValid(jwt);
    if (jwt && !isValid) {
      this.logout('login');
    }
    return isValid;
  }

  isJwtValid(jwt: string): boolean {
    const tokenInfo = this.getDecodedAccessToken(jwt);
    if (tokenInfo === null) {
      return false;
    }
    const exp = tokenInfo.exp * 1000;
    const now = Date.now();
    return exp > now;
  }

  clearAuthLocalStorage() {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    localStorage.removeItem('username');
  }

  logout(redirectRouterLink: string) {
    this.clearAuthLocalStorage();
    this.stopRefreshTokenTimer();
    this.router.navigate([redirectRouterLink]);
  }

  getJwt() {
    return localStorage.getItem('token');
  }

  getOrRefreshUserToken() {
    const body = {
      token: this.getJwt()
    };
    const httpOptions = this.returnHttpOptions();
    // @ts-ignore
    return this.http.post(environment.apiGateway + '/auth/token', body, httpOptions);
  }

  returnHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      observe: 'response'
    };
  }

  getCurrentUserPermissions(): UserPermissions {
    const permissionsJson = localStorage.getItem('permissions');
    const permissionsObj = JSON.parse(permissionsJson) as UserPermissions;
    return permissionsObj;
  }

  setCurrentUserPermissions(permissions: UserPermissions): void {
    const permissionsString = JSON.stringify(permissions);
    localStorage.setItem('permissions', permissionsString);
  }

  isCurrentUserSystemAdmin() {
    const permissionsJson = localStorage.getItem('permissions');
    const permissionsObj = JSON.parse(permissionsJson) as UserPermissions;
    return permissionsObj ? permissionsObj.issystemadmin : false;
  }

  isCurrentUserVendorAdmin() {
    const permissionsJson = localStorage.getItem('permissions');
    const permissionsObj = JSON.parse(permissionsJson) as UserPermissions;
    return permissionsObj ? permissionsObj.vendoradmin.length > 0 : false;
  }

  getCurrentUserId(): string {
    const permissionsJson = localStorage.getItem('permissions');
    const permissionsObj = JSON.parse(permissionsJson) as UserPermissions;
    return permissionsObj?.userid ? permissionsObj.userid : null;
  }

  getCurrentUsername(): string {
    return localStorage.getItem('username');
  }

  getCurrentUserMarketAdminIds(): string[] {
    const permissionsJson = localStorage.getItem('permissions');
    const permissionsObj = JSON.parse(permissionsJson) as UserPermissions;
    return permissionsObj?.marketadmin ? permissionsObj.marketadmin : null;
  }

  getCurrentUserVendorAdminIds(): string[] {
    const permissionsJson = localStorage.getItem('permissions');
    const permissionsObj = JSON.parse(permissionsJson) as UserPermissions;
    return permissionsObj?.marketadmin ? permissionsObj.vendoradmin : null;
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }

  startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const token = this.getJwt();
    const tokenInfo = this.getDecodedAccessToken(token);

    if (tokenInfo === null) {
      this.logout('login');
    }

    // set a timeout to refresh the token 5 minutes before it expires
    const refreshTime = (tokenInfo.exp - 300) * 1000;
    const now = Date.now();
    const timeout = refreshTime - now;
    this.refreshTokenTimeout = setTimeout(() => this.getOrRefreshUserToken().subscribe(res => {

      // @ts-ignore
      localStorage.setItem('token', res.body.token);
      this.startRefreshTokenTimer();
    }), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  public verifyEmailConfirmation(id) {
    const fullQueryUrl = environment.apiGateway + '/auth/email_confirmation/' + id;
    return this.http.get<any>(fullQueryUrl);
  }

  public verifyPasswordResetEmail(id) {
    const fullQueryUrl = environment.apiGateway + '/auth/password_reset/confirmation/' + id;
    return this.http.get<any>(fullQueryUrl);
  }

  public sendAccountSignupConfirmationEmail() {
    const id = this.getCurrentUserId();
    const body = {
      UserID: id,
    };
    return this.http.post(environment.apiGateway + '/auth/signup/confirmation', body, { observe: "response" });
  }

  public sendConfirmationEmail() {
    return this.sendAccountSignupConfirmationEmail();
    // TODO: Case 12217
    // const id = this.getCurrentUserId();
    // const body = {
    //   UserID: id,
    // };
    // return this.http.post(environment.apiGateway + '/auth/email_confirmation', body);
  }
}
