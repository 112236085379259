import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GetFormattedPhoneNumber, Phone } from '../models/phone';
import { SocialMedia } from '../models/socialMedia';

interface ContactUs {
  website: string;
  phone: Phone;
  email: string;
  socialmedia: SocialMedia;
}

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  website = '';
  phone = '';
  email = '';
  socialmedia = {};

  constructor(public dialogRef: MatDialogRef<ContactUsComponent>, @Inject(MAT_DIALOG_DATA) public data: ContactUs) { }

  ngOnInit() {
    this.phone = GetFormattedPhoneNumber(this.data.phone);
    if (this.data.website && this.data.website.length > 0)
      this.website = this.data.website;
    if (this.data.email && this.data.email.length > 0)
      this.email = this.data.email;
    if (this.data.socialmedia)
      this.socialmedia = this.data.socialmedia;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
